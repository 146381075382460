import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ContainerComponent } from './shell/container/container.component';
import { AlertComponent } from './components/alert/alert.component';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, ContainerComponent, AlertComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'], // Changed to styleUrls
})
export class AppComponent implements OnInit, OnDestroy {
    private titleMessages: string[] = [
        'Ylabs Data Admin',
        'Welcome to the Future'
    ];
    private currentIndex: number = 0;
    private intervalId: any;

    constructor() {
        if (environment.production) {
            this.setFavicon('assets/favicon.ico');
            this.titleMessages.push("PRODUCTION MODE")
        } else {
            this.setFavicon('assets/favicon-dev.ico');
            this.titleMessages.push("DEVELOPMENT MODE")
        }
    }

    ngOnInit() {
        this.startTitleAnimation();
    }

    ngOnDestroy() {
        this.stopTitleAnimation();
    }

    private setFavicon(faviconPath: string) {
        const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = faviconPath;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    private startTitleAnimation() {
        this.updateTitle();

        this.intervalId = setInterval(() => {
            this.updateTitle();
        }, 2000);
    }

    private stopTitleAnimation() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    private updateTitle() {
        document.title = this.titleMessages[this.currentIndex];
        this.currentIndex = (this.currentIndex + 1) % this.titleMessages.length;
    }
}
