import {Component, OnInit} from '@angular/core';
import {RegionalTextBundle} from '../../interfaces/TextBundle.interface';
import {TextBundlesService} from '../../services/text-bundles.service';

@Component({
    selector: 'app-regional-capture-data',
    standalone: true,
    imports: [],
    templateUrl: './regional-capture-data.component.html',
    styleUrl: './regional-capture-data.component.css'
})
export class RegionalCaptureDataComponent implements OnInit {
    public regionalCaptureData: RegionalTextBundle[] = [];

    constructor(private textBundleService: TextBundlesService) {
    }

    ngOnInit() {
        // TODO: subscribe to all regionalCapture Data
    }
}
