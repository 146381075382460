import {inject, Injectable} from '@angular/core';
import {collection, collectionData, doc, Firestore, getDoc, getDocs, query, where} from '@angular/fire/firestore';
import {forkJoin, from, map, Observable} from 'rxjs';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {User} from '../interfaces/User.interface';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private firestore: Firestore = inject(Firestore);
    private userPath = 'Users';

    constructor() {
    }

    getRef(userId: string) {
        return doc(this.firestore, `${this.userPath}/${userId}`);
    }

    getUserData(userId: string): Observable<User | undefined> {
        const userDocRef = this.getRef(userId);

        return from(getDoc(userDocRef)).pipe(
            map(docSnapshot => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data() as User;
                    return {...userData, id: docSnapshot.id} as User;
                } else {
                    return undefined;
                }
            })
        );
    }

    getUserDataByDocRef(userDocRef: DocumentReference): Observable<User | undefined> {
        return from(getDoc(userDocRef)).pipe(
            map(docSnapshot => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data() as User;
                    return {...userData, id: docSnapshot.id} as User;
                } else {
                    return undefined;
                }
            })
        );
    }
    
    getAllUsers(): Observable<User[]> {
        return collectionData(collection(this.firestore, this.userPath), {idField: 'id'}).pipe(
            map(users => users.map(user => {
                console.log('User:', user);
                return {
                    ...user,
                    id: user.id
                } as User;
            }))
        );
    }

    queryUsers(matchString: string): Observable<User[]> {
        const usersCollection = collection(this.firestore, this.userPath);
        const queries = [
            query(usersCollection, where('userName', '==', matchString)),
            query(usersCollection, where('lastName', '==', matchString)),
            query(usersCollection, where('id', '==', matchString)),
            query(usersCollection, where('country', '==', matchString)),
            query(usersCollection, where('email', '==', matchString))
        ];

        const observables = queries.map(q => from(getDocs(q)).pipe(
            map(querySnapshot => querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}) as User))
        ));

        return forkJoin(observables).pipe(
            map(results => {
                const combined = results.reduce((acc, val) => acc.concat(val), [] as User[]);
                return combined.filter((user, index, self) =>
                    index === self.findIndex(t => t.id === user.id));
            })
        );
    }

    getUserProfileImage(userId: string): Observable<string> {
        return this.getUserData(userId).pipe(
            map(user => user?.photo_url ?? '')
        );
    }
}
