import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AlertService} from '../../services/alert.service';
import {FirebaseFunctionService} from '../../services/firebase-function.service';
import {AvailableLanguagesResponse} from '../../interfaces/firebase_functions';
import {DialectMatrixService} from '../../services/dialect-matrix.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../services/user.service';
import {User} from '../../interfaces/User.interface';
import {catchError, Observable, of, switchMap, tap} from 'rxjs';

interface GooglePlacesPrediction {
    description: string;
    place_id: string;
}

interface Stay {
    place_id: string;
    duration: number;
    location: string;
    current: boolean;
    coordinates?: {
        lat: number;
        lng: number;
    };
    postalCode?: string;
}

@Component({
    selector: 'app-region-matcher-view',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    templateUrl: './region-matcher-view.component.html',
    styleUrls: ['./region-matcher-view.component.css'],
    animations: [
        trigger('slideInOut', [
            state('in', style({transform: 'translateX(0)'})),
            transition('void => *', [
                style({transform: 'translateX(100%)'}),
                animate('400ms ease-in-out')
            ]),
            transition('* => void', [
                animate('400ms ease-in-out', style({transform: 'translateX(-100%)'}))
            ])
        ])
    ]
})
export class RegionMatcherViewComponent implements OnInit {
    @ViewChild('addressInput') addressInputRef!: ElementRef<HTMLInputElement>;
    @Output() closeRegionalMatcher: EventEmitter<any> = new EventEmitter<any>();

    protected readonly Object = Object;

    private autocompleteService!: google.maps.places.AutocompleteService;
    private placesService!: google.maps.places.PlacesService;
    private manualDurationInputVisible: boolean = false;

    public countryKey: string | null = null;
    public targetLanguage: string | null = null;
    public targetRegion!: string;
    private userId: string | null = null;
    user$!: Observable<User | undefined>;

    currentStep: "selectCurrentStay" | "selectAge" | "selectStayLocation" | "showRegionAssignment" | "selectRegion" = "selectRegion";
    gotInitialLocation: boolean = false;

    results: GooglePlacesPrediction[] | null = null;
    selectedPlace: GooglePlacesPrediction | null = null;
    availableLanguages!: AvailableLanguagesResponse;
    dialectMatrix: any | null = null;
    regionThumbnail: string | null = null;

    inputAge: number | null = null;
    inputDuration: number | null = null;
    yearsLeft: number | null = null;
    stays: Stay[] = [];

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private firebaseFunctionService: FirebaseFunctionService,
        private dialectMatrixService: DialectMatrixService,
        private userService: UserService,
    ) {
        this.initializeUserData();
        this.loadAvailableLanguages();
    }

    async ngOnInit(): Promise<void> {
        try {
            const {AutocompleteService, PlacesService} = await this.loadGooglePlacesLibrary();
            this.setupGooglePlacesServices(AutocompleteService, PlacesService);
        } catch (error) {
            this.alertService.showAlert('Error', 'Google Maps services could not be initialized');
        }
    }

    private initializeUserData(): void {
        const userId = this.route.snapshot.paramMap.get('userID');
        if (userId) {
            this.userId = userId;
            this.user$ = this.userService.getUserData(userId).pipe(
                catchError(error => {
                    this.alertService.showAlert('Error', 'Failed to fetch user data');
                    return of(undefined);
                })
            );
        }
    }

    private async loadGooglePlacesLibrary(): Promise<typeof google.maps.places> {
        // @ts-ignore
        return google.maps.importLibrary("places");
    }

    private setupGooglePlacesServices(AutocompleteService: typeof google.maps.places.AutocompleteService, PlacesService: typeof google.maps.places.PlacesService): void {
        const offscreenDiv = document.createElement('div');
        offscreenDiv.style.display = 'none';
        document.body.appendChild(offscreenDiv);

        this.autocompleteService = new AutocompleteService();
        this.placesService = new PlacesService(offscreenDiv);
    }

    private loadAvailableLanguages(): void {
        this.firebaseFunctionService.getAvailableLanguages().subscribe(
            languages => {
                console.log("Available languages", languages);
                this.availableLanguages = languages;
            },
            error => this.alertService.showAlert('Error', 'Failed to load available languages')
        );
    }

    private getPlaceDetails(placeId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.placesService.getDetails({placeId: placeId}, (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                    console.log("place", place)
                    const details = {
                        coordinates: {
                            lat: place.geometry?.location?.lat(),
                            lng: place.geometry?.location?.lng(),
                        },
                        address: place.formatted_address,
                        postalCode: place.address_components?.find(component => component.types.includes('postal_code'))?.short_name
                    };
                    resolve(details);
                } else {
                    reject(`Failed to retrieve place details: ${status}`);
                }
            });
        });
    }

    private async processStays() {
        const promises = this.stays.map(async (stay, index) => {
            try {
                const details = await this.getPlaceDetails(stay.place_id);
                this.stays[index].coordinates = details.coordinates;
                this.stays[index].postalCode = details.postalCode;
                console.log(`Updated stay for ${stay.place_id}:`, this.stays[index]);
            } catch (error) {
                console.error(`Error processing stay for ${stay.place_id}:`, error);
            }
        });

        await Promise.all(promises);
    }

    private validateAgeAndTargetLanguage(errorMessage: string): boolean {
        if (this.yearsLeft === null || this.inputAge === null || this.targetLanguage === null) {
            this.alertService.showAlert(errorMessage, errorMessage);
            return false;
        }
        return true;
    }

    private async evaluateRegionAssignment() {
        if (this.yearsLeft === null || this.inputAge === null) {
            console.warn("Years left or input age is null");
            return;
        }

        if (this.yearsLeft < 0.2 * this.inputAge) {
            this.currentStep = "showRegionAssignment";
            try {
                await this.processStays();
                this.obtainDialectMatrix();
            } catch (error) {
                console.error('Error processing stays:', error);
            }
        } else {
            this.currentStep = "selectStayLocation";
        }
    }

    private obtainDialectMatrix() {
        console.log(this.stays)
        this.firebaseFunctionService.getDialectMatrix(this.targetLanguage as string, this.stays).pipe(
            switchMap(matrix => {
                this.dialectMatrix = matrix;
                const keyWithHighestValue = this.getHighestCantonScore();
                this.targetRegion = keyWithHighestValue;
                return this.dialectMatrixService.saveRegionForUser(
                    this.userId as string, this.targetLanguage as string, this.dialectMatrix, keyWithHighestValue, this.countryKey as string
                );
            }),
            switchMap(() => {
                console.log("Region saved");
                return this.dialectMatrixService.getRegionThumbnail(this.countryKey as string, this.targetRegion);
            }),
            tap(url => {
                url.subscribe(thumbnail => {
                    this.regionThumbnail = thumbnail;
                })
            }),
            catchError(error => {
                console.error("Failed to process dialect matrix or save region", error);
                return of(null);
            })
        ).subscribe();
    }

    private getHighestCantonScore(): string {
        console.log("Dialect matrix", this.dialectMatrix);
        const cantonScores = this.dialectMatrix['canton_scores'];
        return Object.keys(cantonScores).reduce((a, b) => cantonScores[a] > cantonScores[b] ? a : b);
    }

    public onInputChanged(event: Event): void {
        const input = (event.target as HTMLInputElement).value;
        if (input.length === 0) {
            this.results = [];
            return;
        }

        if (input) {
            this.autocompleteService.getPlacePredictions({
                input: input,
            }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    this.results = predictions
                }
            });
        }
    }

    public selectPlace(result: GooglePlacesPrediction) {
        this.selectedPlace = result;
    }

    public resetPlace() {
        this.selectedPlace = null;
        this.results = null;
    }

    public async toggleNext() {
        const validationError = "Please fill age first";
        switch (this.currentStep) {
            case "selectAge":
                this.currentStep = "selectCurrentStay";
                break;

            case "selectRegion":
                if (this.targetLanguage) {
                    this.currentStep = "selectAge";
                }
                break;

            case "selectCurrentStay":
            case "selectStayLocation":
                if (this.validateAgeAndTargetLanguage(validationError)) {
                    await this.evaluateRegionAssignment();
                }
                break;
        }
    }

    public onAgeInputChange(event: any): void {
        const value = event.target.value;

        if (value.includes(".")) {
            event.target.value = this.inputAge;
            return;
        }

        if (value >= 0 && value <= 130) {
            this.inputAge = Number(value);
            this.yearsLeft = this.inputAge;
        } else {
            event.target.value = this.inputAge;
        }
    }

    public onDurationChange(event: any): void {
        const stayDuration = Number(event.target.value);
        const age = this.inputAge;
        const isInteger = Number.isInteger(Number(stayDuration));

        if (!this.gotInitialLocation) {
            if (!age) {
                this.alertService.showAlert("Please fill age first", "Please fill age first");
                return
            }

            if (!isInteger || stayDuration < 0) {
                event.target.value = null
                this.inputDuration = null;
                return;
            }

            if (stayDuration > age) {
                this.alertService.showAlert("Stay duration can't be more than age", "setting duration to age");
                event.target.value = age;
                this.inputDuration = age;
            } else {
                this.inputDuration = stayDuration;
            }
        } else {
            if (!this.yearsLeft) {
                console.log("years left is null")
                return;
            }

            if (!isInteger || stayDuration < 0) {
                console.log(isInteger, stayDuration)
                event.target.value = null
                this.inputDuration = null;
                return;
            }

            if (stayDuration > this.yearsLeft) {
                this.alertService.showAlert("Stay duration can't be more than years left", "setting duration to years left");
                event.target.value = this.yearsLeft;
                this.inputDuration = this.yearsLeft;
            } else {
                this.inputDuration = stayDuration;
            }
        }
    }

    public approveCurrentStay(inputDuration: null | number = null) {
        if (inputDuration) {
            this.inputDuration = inputDuration;
        }

        if (!this.inputAge || !this.inputDuration || !this.selectedPlace) {
            this.alertService.showAlert("Please fill all the fields", "Please fill all the fields");
            console.log(this.inputAge, this.inputDuration, this.selectedPlace)
            return;
        }

        this.stays.push({
            place_id: this.selectedPlace.place_id,
            duration: this.inputDuration,
            location: this.selectedPlace.description,
            current: !this.gotInitialLocation
        });

        if (!this.yearsLeft) {
            console.log("years left is null")
            return;
        }

        this.yearsLeft = this.yearsLeft - this.inputDuration;

        this.inputDuration = null;
        this.selectedPlace = null;
        this.results = null;
        this.gotInitialLocation = true

        this.toggleNext();
    }

    public setCurrentStayDuration(number: number) {
        this.inputDuration = number;
    }

    public close() {
        // reset the entire state of the component
        this.closeRegionalMatcher.emit();
        this.manualDurationInputVisible = false;
        this.results = null;
        this.selectedPlace = null;
        this.currentStep = "selectAge";
        this.inputAge = null;
        this.inputDuration = null;
        this.yearsLeft = null;
        this.gotInitialLocation = false;
        this.stays = [];
    }

    public setCountry(country: string) {
        this.countryKey = country;
    }

    public setTargetLanguage(result: string) {
        console.log("Setting target language", result);
        this.targetLanguage = result;
    }

    public showManuelDurationInput() {
        this.manualDurationInputVisible = true;
    }

    get isCurrentStayComplete() {
        return this.inputAge && this.inputDuration && this.selectedPlace;
    }

    get isAgeProvable(): Boolean {
        return this.inputAge !== null && this.inputAge >= 1 && this.inputAge <= 130;
    }

    get isTargetLanguageApproval(): Boolean {
        return this.targetLanguage !== null;
    }

    get _showManuelDurationInput() {
        return this.manualDurationInputVisible;
    }
}
