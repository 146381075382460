import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {BaseTextBundle} from '../../interfaces/TextBundle.interface';
import {CaptureEventService} from '../../services/capture-event.service';
import {TextBundlesService} from '../../services/text-bundles.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AsyncPipe, JsonPipe, NgClass, NgForOf, NgIf} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import {DatepickerOptions, initFlowbite} from 'flowbite';
import {Datepicker} from 'flowbite-datepicker';
import {AlertService} from '../../services/alert.service';

interface GooglePlacesPrediction {
    description: string;
    place_id: string;
}

@Component({
    selector: 'app-capture-event-register',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgForOf,
        JsonPipe,
        NgIf,
        NgClass,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        AsyncPipe
    ],
    templateUrl: './capture-event-register.component.html',
    styleUrls: ['./capture-event-register.component.css'],
    animations: [
        trigger('slideEffect', [
            state('disabled', style({transform: 'translateX(0)'})),
            state('enabled', style({transform: 'translateX(100%)'})),
            transition('disabled <=> enabled', animate('200ms ease-in-out'))
        ])
    ]
})
export class CaptureEventRegisterComponent implements OnInit {
    imageSrc: string | File | null = null;
    imageFile!: File;

    imageSrcWeb: string | File | null = null;
    imageFileWeb!: File;

    eventForm!: FormGroup;
    availableBaseTextBundles$: Observable<BaseTextBundle[]>;

    datetimePicker!: Datepicker;
    results: GooglePlacesPrediction[] | null = null;
    selectedPlace: GooglePlacesPrediction | null = null;

    isLoading = false;

    selectedTextBundle: BaseTextBundle | null = null;

    @ViewChild('placesInput') addressInputRef!: ElementRef<HTMLInputElement>;

    private autocompleteService!: google.maps.places.AutocompleteService;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private textBundleService: TextBundlesService,
        private captureEventService: CaptureEventService,
        private alertService: AlertService
    ) {
        this.availableBaseTextBundles$ = this.textBundleService.getAllBaseTextBundlesData();
    }

    async ngOnInit() {
        this.eventForm = this.fb.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            originBaselTextBundleSelection: [undefined, Validators.required],
            descriptionLong: [''],
            calendlyLink: '',
            placeId: "",
            placeDescription: "",
            eventDate: [null],
            isPublicEvent: [false]
        })

        this.setupInitialValues();
        initFlowbite();

        const datepickerEl = document.getElementById('default-datepicker');

        const options: DatepickerOptions = {
            autohide: true,
            format: 'mm/dd/yyyy',
            maxDate: null,
            minDate: null,
            orientation: 'top',
        };

        if (!datepickerEl) {
            console.error('Datepicker element not found');
            return;
        }

        this.datetimePicker = new Datepicker(datepickerEl, options);

        this.eventForm.get('isPublicEvent')!.valueChanges.subscribe(isPublic => {
            this.toggleFieldValidators(isPublic);
        });

        try {
            const {AutocompleteService, PlacesService} = await this.loadGooglePlacesLibrary();
            this.setupGooglePlacesServices(AutocompleteService, PlacesService);
        } catch (error) {
            this.alertService.showAlert('Error', 'Google Maps services could not be initialized');
        }
    }

    private setupGooglePlacesServices(AutocompleteService: typeof google.maps.places.AutocompleteService, PlacesService: typeof google.maps.places.PlacesService): void {
        const offscreenDiv = document.createElement('div');
        offscreenDiv.style.display = 'none';
        document.body.appendChild(offscreenDiv);

        this.autocompleteService = new AutocompleteService();
    }

    private async loadGooglePlacesLibrary(): Promise<typeof google.maps.places> {
        // @ts-ignore
        return google.maps.importLibrary("places");
    }


    setupInitialValues() {
        this.availableBaseTextBundles$.pipe(
            startWith([])
        ).subscribe(textBundles => {
            this.eventForm.patchValue({
                originBaselTextBundleSelection: textBundles[0],
            });
            this.selectedTextBundle = textBundles[0];
        });
    }

    setBaseTextBundle(event: any) {
        const selectedTextBundleId = event.target.value;

        if (selectedTextBundleId) {
            this.availableBaseTextBundles$.subscribe(bundles => {
                const bundle = bundles.find(bundle => bundle.id === selectedTextBundleId);
                if (bundle) {
                    this.selectedTextBundle = bundle;
                    console.log(this.selectedTextBundle);
                }
            });
        }
    }

    createCaptureEvent(): void {
        if (this.isEventFormValid) {
            this.isLoading = true;
            const newCaptureEvent = this.eventForm.value;
            newCaptureEvent.originBaseTextBundle = this.selectedTextBundle;

            if (!this.eventForm.get('isPublicEvent')!.value) {
                newCaptureEvent.country = null;
                newCaptureEvent.city = null;
                newCaptureEvent.address = null;
            } else {
                newCaptureEvent.placeId = this.selectedPlace?.place_id;
                newCaptureEvent.placeDescription = this.selectedPlace?.description;
                newCaptureEvent.eventDescription = this.eventForm.get('descriptionLong')!.value;
            }

            this.captureEventService.create(newCaptureEvent, this.imageFile, this.imageFileWeb).then(_ => {
                this.navigateToCaptureEventList();
                this.isLoading = false;
            });
        } else {
            console.log(this.eventForm.value);
            alert('Please complete all required fields and ensure an image is uploaded.');
        }
    }

    onFileSelected(event: Event, web = false): void {
        const file = (event.target as HTMLInputElement).files?.[0];

        if (web) {
            console.log('web image selected');
            if (file) {
                console.log('web image selected');
                this.imageFileWeb = file;
                this.imageSrcWeb = URL.createObjectURL(file);
            }
        } else {
            if (file) {
                this.imageFile = file;
                this.imageSrc = URL.createObjectURL(file);
            }
        }
    }

    deleteImage(web = false): void {
        if (web) {
            this.imageSrcWeb = null;
        } else {
            this.imageSrc = null;
        }
    }

    navigateToCaptureEventList(): void {
        this.router.navigate(['app/capture-events']);
    }

    togglePublicEvent(): void {
        const newState = !this.eventForm.get('isPublicEvent')!.value;
        this.eventForm.get('isPublicEvent')!.setValue(newState);
    }

    toggleFieldValidators(isPublic: boolean): void {
        const fieldsToToggle = ['calendlyLink', 'eventDate'];
        fieldsToToggle.forEach(field => {
            const control = this.eventForm.get(field);
            if (isPublic) {
                control?.setValidators(Validators.required);
            } else {
                control?.clearValidators();
            }
            control?.updateValueAndValidity();
        });
    }

    get isPublicEvent(): boolean {
        return this.eventForm.get('isPublicEvent')!.value;
    }

    get isEventFormValid(): boolean {
        let isValid = false;

        if (this.eventForm.get("isPublicEvent")!.value) {
            this.eventForm.get('eventDate')!.setValue(this.datetimePicker.getDate());
            isValid = this.eventForm.valid && this.imageSrc != null && this.imageSrcWeb != null && this.datetimePicker.getDate() != null && this.selectedPlace != null;
        } else {
            isValid = this.eventForm.valid && this.imageSrc != null;
        }

        console.log('isEventFormValid', isValid);
        return isValid;
    }

    public onInputChanged(event: Event): void {
        const input = (event.target as HTMLInputElement).value;
        if (input.length === 0) {
            this.results = [];
            return;
        }

        if (input) {
            this.autocompleteService.getPlacePredictions({
                input: input,
            }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    this.results = predictions
                }
            });
        }
    }

    public selectPlace(result: GooglePlacesPrediction) {
        this.selectedPlace = result;
        this.addressInputRef.nativeElement.value = result.description;
        this.results = null;
    }

}
