<main class="w-full p-8 border-t-4 border-orange-500 bg-white overflow-y-scroll dark:bg-gray-900 relative">
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8">
		<div class="min-w-0 flex-1">
			<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">Capture Event Registration</h2>
		</div>
	</header>
	
	<div class="bg-white bg-opacity-85 flex justify-center items-center fixed top-0 bottom-0 left-0 right-0 z-50" *ngIf="isLoading">
		<div class="text-center">
			<div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full mb-8" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
			<div class="text-lg font-bold mt-4">
				Creating Capture Event, Stay calm and hold on...
			</div>
		</div>
	</div>
	
	<form [formGroup]="eventForm">
		<div class="space-y-12 bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
			<div class="border-b border-gray-200 dark:border-white/10 pb-20">
				<h2 class="text-base font-semibold leading-7">Capture Event Meta</h2>
				<p class="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">This information will be displayed publicly so be careful.</p>
				
				<div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
					
					<div class="col-span-full flex gap-6">
						<div class="w-full">
							<label for="name" class="block text-sm font-medium leading-6">Capture Event Name</label>
							<div class="mt-2 flex rounded-md bg-gray-50 dark:bg-white/10 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-500">
								<input type="text" formControlName="name" id="name" class="flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6" placeholder="Capture Event - Luzärn">
							</div>
							
							<div class="col-span-full my-5">
								<label for="description" class="block text-sm font-medium leading-6">Capture Event Description</label>
								<div class="mt-2">
									<textarea id="description" formControlName="description" rows="3" class="block w-full rounded-md border-0 bg-gray-50 dark:bg-white/10 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"></textarea>
								</div>
								<p class="mt-3 text-sm leading-6 text-gray-500 dark:text-gray-400">Explain what the capture session is used for. The participants will see this text.</p>
							</div>
							
							<div class="border-t border-gray-200 dark:border-white/10 pt-5">
								<h2 class="text-base font-semibold leading-7">Base Text Bundle</h2>
								<p class="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">We'll create a copy of all TextSnippets which will be shown to the user.</p>
								
								<div class="sm:col-span-3 mt-4">
									<label for="originBaselTextBundleSelection" class="mb-2 block text-sm font-medium leading-6">Select Origin Text Bundle</label>
									<select id="originBaselTextBundleSelection" (change)="setBaseTextBundle($event)" class="block w-full rounded-md border-0 bg-gray-50 dark:bg-white/10 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6">
										<option *ngFor="let textBundle of availableBaseTextBundles$ | async" [value]="textBundle.id">{{ textBundle.name }}</option>
									</select>
								</div>
							</div>
						</div>
						
						<div class="w-full flex-grow">
							<div class="flex justify-between items-center">
								<label class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Thumbnail</label>
								<button *ngIf="imageSrc" (click)="deleteImage()" class="text-gray-900 dark:text-white hover:text-red-500">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
									</svg>
								</button>
							</div>
							<div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-300 dark:border-white/25 p-6 h-full">
								<div *ngIf="!imageSrc" class="text-center flex justify-center flex-col">
									<svg class="mx-auto h-12 w-12 text-gray-500 dark:text-gray-400" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
										<path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd"/>
									</svg>
									<div class="mt-4 flex text-sm leading-6 text-gray-500 dark:text-gray-400">
										<label for="file-upload" class="relative cursor-pointer rounded-md bg-gray-50 dark:bg-gray-900 font-semibold text-gray-900 dark:text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-sky-500">
											<span>Upload a file</span>
											<input id="file-upload" name="file-upload" type="file" class="sr-only" (change)="onFileSelected($event)">
										</label>
										<p class="pl-1">or drag and drop</p>
									</div>
									<p class="text-xs leading-5 text-gray-400">PNG, JPG up to 10MB</p>
								</div>
								<img *ngIf="imageSrc" [src]="imageSrc" class="object-cover rounded-lg max-h-[600px]" alt="Uploaded Event Thumbnail">
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="border-b border-gray-200 dark:border-white/10">
				<h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-white">Capture Event Location</h2>
				<p class="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">Meta information which will be used to categorize the Recorded Audio Snippets</p>
				
				<dl class="space-y-6 divide-y divide-gray-300 dark:divide-gray-700 text-sm leading-6 pb-5 ">
					<div class="flex pt-6">
						<dt class="flex-none pr-6 font-medium sm:w-64" id="timezone-option-label">Public Capture Event</dt>
						<dd class="flex flex-auto items-center justify-end">
							<button type="button"
							        [attr.aria-checked]="isPublicEvent ? 'true' : 'false'"
							        [ngClass]="isPublicEvent ? 'bg-sky-600' : 'bg-gray-600'"
							        (click)="togglePublicEvent()"
							        class="flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-500 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
							        role="switch">
                    <span aria-hidden="true"
                          [@slideEffect]="isPublicEvent ? 'enabled' : 'disabled'"
                          class="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-500 transition duration-200 ease-in-out"></span>
							</button>
						</dd>
					</div>
				</dl>
				
				<div [hidden]="!isPublicEvent">
					<div class="col-span-full flex w-full gap-5 mb-[70px] border-t border-gray-200">
						<div class="w-full mt-5 flex flex-col">
							
							<div class="w-full">
								<label for="descriptionLong" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Capture Event Description - Location Specific</label>
								<div class="mt-2">
									<textarea id="descriptionLong" formControlName="descriptionLong" rows="6" class="block w-full rounded-md bg-white dark:bg-gray-800 border border-gray-300 dark:border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-sky-500 dark:focus:ring-sky-500 sm:text-sm sm:leading-6"></textarea>
								</div>
								<p class="mt-3 text-sm text-gray-500 dark:text-gray-400">Explain what the capture session is used for. The participants will see this text.</p>
								
								<label for="calendlyLink" class="block text-sm font-medium leading-6 mt-5">Calendly Link</label>
								<div class="mt-2 flex rounded-md bg-white dark:bg-gray-800 border border-gray-300 dark:border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus:ring-2 focus:ring-inset focus:ring-sky-500 dark:focus:ring-sky-500 sm:text-sm sm:leading-6">
									<input type="text" formControlName="calendlyLink" id="calendlyLink" class="flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6" placeholder="https://calendly.com/yaron-fanger-ylabs/voice-capture-event-luzern">
								</div>
								<p class="mt-3 text-sm text-gray-500 dark:text-gray-400">The direct Link to the Calendly Event Registration Panel</p>
							
							</div>
							
							<div class="relative w-full mt-5">
								<div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
									<svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
										<path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
									</svg>
								</div>
								<input id="default-datepicker" type="text" class="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 dark:focus:ring-blue-500 focus:border-blue-500 dark:focus:border-blue-500 block w-full ps-10 p-2.5 dark:placeholder-gray-400"
								       placeholder="Select date">
							</div>
							
							<div class="w-full mt-5">
								<label for="descriptionLong" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Capture Event Description - Location Specific</label>
								
								<div class="transform rounded-xl bg-white p-2 ring-1 ring-black ring-opacity-5 transition-all min-w-[500px] mt-5">
									<input type="text" class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm" placeholder="Search..."
									       role="combobox" aria-expanded="false" aria-controls="options" (input)="onInputChanged($event)" #placesInput>
									
									<ul *ngIf="results !== null" class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800" role="listbox">
										<li *ngFor="let result of results" class="cursor-default select-none rounded-md px-4 py-2 hover:bg-sky-600 hover:text-white transition" role="option" tabindex="-1" (click)="selectPlace(result)">
											{{ result.description }}
										</li>
									</ul>
									
									<div *ngIf="results?.length === 0 && results !== null" class="px-4 py-14 text-center sm:px-14">
										<svg class="mx-auto h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
											<path stroke-linecap="round" stroke-linejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"/>
										</svg>
										<p class="mt-4 text-sm text-gray-900">Sorry, we couldn't find the region you described.</p>
									</div>
								</div>
							</div>
						</div>
						
						<div class="w-full min-h-[300px] flex-grow">
							<div class="flex justify-between items-center">
								<label class="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-5">Website Cover</label>
								<button *ngIf="imageSrcWeb" (click)="deleteImage(true)" class="text-gray-900 dark:text-white hover:text-red-500">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
									</svg>
								</button>
							</div>
							<div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-300 dark:border-white/25 p-6 h-full">
								<div *ngIf="!imageSrcWeb" class="text-center flex justify-center flex-col">
									<svg class="mx-auto h-12 w-12 text-gray-500 dark:text-gray-400" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
										<path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd"/>
									</svg>
									<div class="mt-4 flex text-sm leading-6 text-gray-500 dark:text-gray-400">
										<label for="file-upload-two" class="relative cursor-pointer rounded-md bg-gray-50 dark:bg-gray-900 font-semibold text-gray-900 dark:text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-sky-500">
											<span>Upload a file</span>
											<input id="file-upload-two" name="file-upload-two" type="file" class="sr-only" (change)="onFileSelected($event, true)">
										</label>
										<p class="pl-1">or drag and drop</p>
									</div>
									<p class="text-xs leading-5 text-gray-400">PNG, JPG up to 10MB</p>
								</div>
								<img *ngIf="imageSrcWeb" [src]="imageSrcWeb" class="object-contain rounded-lg" alt="Uploaded Event Thumbnail">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="mt-6 flex items-center justify-end gap-x-6">
			<button (click)="navigateToCaptureEventList()" type="button" class="text-sm font-semibold leading-6 text-gray-700 dark:text-gray-300">
				Cancel<span class="inline-flex items-center rounded px-1 ml-2 text-gray-800 dark:text-gray-500">esc</span>
			</button>
			<button (click)="createCaptureEvent()" type="submit" class="rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500 disabled:bg-gray-600 disabled:text-gray-200">
				Create Capture Event
			</button>
		</div>
	</form>
</main>
