<main class="relative w-full p-8 border-t-4 border-pink-400 overflow-y-scroll flex flex-col flex-grow bg-white dark:bg-gray-900 ">
	<app-search [(show)]="searchOpen" searchType="captureEvents" (captureEventSelect)="enrollInCaptureEvent($event)"></app-search>
	<app-modal [(showModal)]="deactivationModalVisible" (deactivateAccountEvent)="deactivateAccount()"></app-modal>
	
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8 mx-auto max-w-7xl w-full">
		<div class="min-w-0 flex-1">
			<h2 *ngIf="user" class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">{{ user.userName + ", " + user.email }} <span class="font-thin">{{ user?.id }}</span></h2>
		</div>
		<div class="mt-4 flex md:ml-4 md:mt-0">
			<button (click)="goBack()" type="button" class="inline-flex items-center rounded-md bg-gray-200 dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-300 dark:hover:bg-white/20">
				Exit
				<span class="inline-flex items-center bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded px-1 ml-2">
                esc
            </span></button>
		</div>
	</header>
	
	<div class="max-w-7xl w-full mx-auto flex flex-col flex-grow">
		<div class="border-b border-gray-300 dark:border-gray-700 pb-5 sm:pb-0 bg-white dark:bg-gray-900 mb-8 ">
			<div class="mt-3 sm:mt-4">
				<div class="sm:hidden">
					<label for="current-tab" class="sr-only">Select a tab</label>
					<select id="current-tab" name="current-tab" class="block w-full rounded-md border-gray-300 dark:border-gray-600 py-2 pl-3 pr-10 text-base text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-800 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm">
						<option selected>Overview</option>
						<option>Enrolled Capture Events</option>
					</select>
				</div>
				
				<div class="hidden sm:block">
					<nav class="-mb-px flex space-x-8">
						<a (click)="setViewState('userData')"
						   [ngClass]="{'border-sky-500 text-sky-400': currentView === 'userData', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': currentView !== 'userData'}"
						   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Overview</a>
						
						<a (click)="setViewState('userEnrolledCaptureEvents')"
						   [ngClass]="{'border-sky-500 text-sky-400': currentView === 'userEnrolledCaptureEvents', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': currentView !== 'userEnrolledCaptureEvents'}"
						   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Enrolled Capture Events</a>
						
						<a (click)="setViewState('management')"
						   [ngClass]="{'border-sky-500 text-sky-400': currentView === 'management', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': currentView !== 'management'}"
						   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Management</a>
						
						<a (click)="setViewState('region')"
						   [ngClass]="{'border-sky-500 text-sky-400': currentView === 'region', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': currentView !== 'region'}"
						   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Region</a>
					</nav>
				</div>
			</div>
		</div>
		
		<ng-container *ngIf="user && currentView === 'userData'">
			<div class="px-4 sm:px-0 bg-white dark:bg-gray-900">
				<h3 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300">User Information</h3>
				<p class="mt-1 max-w-2xl text-sm leading-6 text-gray-700 dark:text-gray-400">Personal details and Agreements.</p>
			</div>
			<div class="mt-6 border-t border-gray-300 dark:border-gray-700">
				<dl class="divide-y divide-gray-300 dark:divide-gray-700">
					<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">User Identification</dt>
						<dd class="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
							<span class="flex-grow">{{ user.id }}</span>
						</dd>
					</div>
					<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">Username</dt>
						<dd class="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
							<span class="flex-grow">{{ user.userName }}</span>
						</dd>
					</div>
					<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">Email address</dt>
						<dd class="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
							<span class="flex-grow">{{ user.email }}</span>
						</dd>
					</div>
					<div *ngIf="getUserBrithDate()" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">Birthdate</dt>
						<dd class="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
							<span class="flex-grow">{{ getUserBrithDate() | date:'fullDate' }}</span>
						</dd>
					</div>
					<div *ngIf="user.motherTongue" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">Mother Tongue</dt>
						<dd class="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
							<span class="flex-grow">{{ user.motherTongue }}</span>
						</dd>
					</div>
					<div *ngIf="user.languages.length > 0" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
						<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">Other Languages</dt>
						<dd class="mt-1 flex text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
							<span class="flex-grow">{{ user.languages }}</span>
						</dd>
					</div>
				</dl>
			</div>
		</ng-container>
		
		<ng-container *ngIf="user && currentView === 'management'">
			<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
				<dt class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">Agreement</dt>
				<dd class="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 sm:col-span-2 sm:mt-0">
					<ul class="divide-y divide-gray-300 dark:divide-gray-700 rounded-md border border-gray-300 dark:border-gray-800" role="list">
						<li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
							<div class="flex w-0 flex-1 items-center">
								<svg aria-hidden="true" class="h-5 w-5 flex-shrink-0 text-gray-500 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
									<path clip-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7 a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" fill-rule="evenodd"/>
								</svg>
								<div class="ml-4 flex min-w-0 flex-1 gap-2">
									<span class="truncate font-medium">capture_event_dfadhfad_agreement.pdf</span>
									<span class="flex-shrink-0 text-gray-500 dark:text-gray-500">2.4mb</span>
								</div>
							</div>
							<div class="ml-4 flex flex-shrink-0 space-x-4">
								<button class="rounded-md font-medium text-sky-400 hover:text-sky-300" type="button">Download</button>
							</div>
						</li>
					</ul>
				</dd>
			</div>
			<div class="flex flex-row gap-14">
				<fieldset>
					<legend class="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Account type</legend>
					<p class="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400">Change the user permissions for additional Backend access</p>
					<div [formGroup]="form" class="mt-6 flex justify-start items-center text-center">
						<div class="flex items-center gap-x-3">
							<input id="normal" type="radio" formControlName="userRole" value="none" class="h-4 w-4 border-gray-300 dark:border-white/10 bg-gray-200 dark:bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900">
							<label for="normal" class="block text-sm font-medium text-gray-900 dark:text-white">Normal</label>
						</div>
						<div class="flex items-center gap-x-3 ml-3">
							<input id="admin" type="radio" formControlName="userRole" value="admin" class="h-4 w-4 border-gray-300 dark:border-white/10 bg-gray-200 dark:bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900">
							<label for="admin" class="block text-sm font-medium text-gray-900 dark:text-white">Admin</label>
						</div>
						<div class="flex items-center gap-x-3 ml-3">
							<input id="superadmin" type="radio" formControlName="userRole" value="superadmin" class="h-4 w-4 border-gray-300 dark:border-white/10 bg-gray-200 dark:bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900">
							<label for="superadmin" class="block text-sm font-medium text-gray-900 dark:text-white">Super Admin</label>
						</div>
					</div>
				</fieldset>
				<div>
					<legend class="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Account state</legend>
					<p class="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400 mb-4">Enable or disable the account</p>
					<button *ngIf="!user.disabled" (click)="openDeactivationModal()" type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto">
						Deactivate
					</button>
					<button *ngIf="user.disabled" (click)="activateUser()" type="button" class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:w-auto">
						Activate
					</button>
				</div>
			</div>
		</ng-container>
		
		<ng-container *ngIf="currentView === 'userEnrolledCaptureEvents'" class="flex flex-grow">
			<div class="bg-white dark:bg-gray-900 w-full">
				<div class="bg-white dark:bg-gray-900 w-full">
					<div class="mx-auto">
						<div class="bg-white dark:bg-gray-900 w-full">
							<div class="flex flex-col flex-grow">
								<div class="sm:flex sm:items-center">
									<div class="sm:flex-auto">
										<h3 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300">Capture Events</h3>
										
										<p class="text-sm text-gray-900 dark:text-gray-300">
											This list contains all the Capture Events that the user is enrolled in.
										</p>
									</div>
									<div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
										<div *ngIf="userEnrolledCaptureEvents.length !== 0" class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
											<a (click)="openSearch()" class="block rounded-md bg-sky-700 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500">
												Enroll in Capture Event
											</a>
										</div>
									</div>
								</div>
								
								<div *ngIf="userEnrolledCaptureEvents.length !== 0" class="mt-8 flow-root">
									<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
										<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
											<table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
												<thead>
												<tr>
													<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">Name</th>
													<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Base Text Bundle</th>
													<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Place Description</th>
													<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
														<span class="sr-only">Edit</span>
													</th>
												</tr>
												</thead>
												<tbody class="divide-y divide-gray-200 dark:divide-gray-800">
												<tr *ngFor="let captureEvent of userEnrolledCaptureEvents">
													<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-0">{{ captureEvent.name }}</td>
													<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 dark:text-gray-300 truncate max-w-[400px]">{{ captureEvent.placeDescription }}</td>
													<td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
														<a [routerLink]="'/app/capture-events/' + captureEvent.id" class="text-sky-400 hover:text-sky-300">Open<span class="sr-only">, Lindsay Walton</span></a>
													</td>
												</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div *ngIf="userEnrolledCaptureEvents.length === 0" class="text-center bg-white dark:bg-gray-900 flex flex-col flex-grow  justify-center">
				<svg class="mx-auto h-12 w-12 text-gray-500 dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
					
					<path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"/>
				</svg>
				
				<h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-300">No Enrollments</h3>
				<p class="mt-1 text-sm text-gray-700 dark:text-gray-400">There are no users enrolled in this Capture Session.</p>
				<div class="mt-6">
					<button (click)="openSearch()" type="button" class="inline-flex items-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700">
						<svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
						</svg>
						Enroll in Capture Event
					</button>
				</div>
			</div>
		</ng-container>
		
		<ng-container *ngIf="currentView === 'region'" class="flex flex-grow">
			<div class="bg-white dark:bg-gray-900 w-full">
				<div class="bg-white dark:bg-gray-900 w-full">
					<div class="mx-auto">
						<div class="bg-white dark:bg-gray-900 w-full">
							<div class="flex flex-col flex-grow">
								<div class="sm:flex sm:items-center">
									<div class="sm:flex-auto" hidden>
										<h3 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300">Region Manager</h3>
										
										<p class="text-sm text-gray-900 dark:text-gray-300">
											This list contains all the Capture Events that the user is enrolled in.
										</p>
									</div>
									<div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" hidden>
										<div *ngIf="userEnrolledCaptureEvents.length !== 0" class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
											<a (click)="openSearch()" class="block rounded-md bg-sky-700 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500">
												Enroll in Capture Event
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div *ngIf="!regionData && !regionMatcherVisible" class="text-center bg-white dark:bg-gray-900 flex flex-col flex-grow  justify-center">
				<svg class="mx-auto h-12 w-12 text-gray-500 dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
					<path stroke-linecap="round" stroke-linejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"/>
				</svg>
				
				<h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-300">No Region Assigned Yet</h3>
				<p class="mt-1 text-sm text-gray-700 dark:text-gray-400">Start the Region Assigment Process Now</p>
				<div class="mt-6">
					<button (click)="openRegionMatcher()" type="button" class="inline-flex items-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700">
						Start Region Assignment
					</button>
				</div>
			</div>
			
			<section *ngIf="regionData && !regionMatcherVisible" class="overflow-hidden bg-white">
				<div class="relative mx-auto max-w-2xl py-24 lg:max-w-4xl">
					<figure class="grid grid-cols-1 gap-x-6 gap-y-8 lg:gap-x-10">
						<div class="relative col-span-2 lg:col-start-1 lg:row-start-2">
							<blockquote class="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
								<p>Analysis indicates that your dialect is significantly shaped by Region {{ regionData.mainRegion }}, {{ regionData.chosenCountry }}.</p>
							</blockquote>
						</div>
						<div class="col-end-1 w-16 lg:row-span-4 lg:w-72">
							<img class="rounded-xl bg-sky-50 lg:rounded-3xl shadow-2xl" [src]="regionData.mainRegionThumbnail" alt="">
						</div>
						<figcaption class="text-base lg:col-start-1 lg:row-start-3">
							<div class="mt-1 text-gray-500">Dialect assigment for <span class="font-semibold text-gray-900">{{ user?.userName ?? 'Unknown Name' }}</span> complete.</div>
							<div class="mt-1 text-gray-500">User is part of the Dialect Group <span class="font-semibold text-gray-900">{{ regionData.chosenLanguage }}</span></div>
							<button type="button" (click)="openRegionMatcher()" class="rounded bg-sky-50 px-2 py-1 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100 mt-4">Restart Region Matching</button>
						</figcaption>
					</figure>
				</div>
				
				<div class="flex gap-12">
					<div class="w-full">
						<div class="sm:flex sm:items-center">
							<div class="sm:flex-auto">
								<h1 class="text-base font-semibold leading-6 text-gray-900">Dialect Matrix</h1>
								<p class="mt-2 text-sm text-gray-700">The following table represents the computed dialect matrix.</p>
							</div>
						</div>
						<div class="mt-8 flow-root">
							<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
									<table class="min-w-full divide-y divide-gray-300">
										<thead>
										<tr>
											<th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Postalcode</th>
											<th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Matrix Value</th>
										</tr>
										</thead>
										<tbody class="divide-y divide-gray-200 bg-white">
										<tr *ngFor="let region of transformDialectVector()">
											<td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ region.key }}</td>
											<td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ region.value }}</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					
					<div class="w-full">
						<div class="sm:flex sm:items-center">
							<div class="sm:flex-auto">
								<h1 class="text-base font-semibold leading-6 text-gray-900">Dialect Matrix Grouped by Canton</h1>
								<p class="mt-2 text-sm text-gray-700">The users Dialect Matrix mapped to all cantons from the users target Country</p>
							</div>
						</div>
						<div class="mt-8 flow-root">
							<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
									<table class="min-w-full divide-y divide-gray-300">
										<thead>
										<tr>
											<th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Postalcode</th>
											<th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Matrix Value</th>
										</tr>
										</thead>
										<tbody class="divide-y divide-gray-200 bg-white">
										<tr *ngFor="let region of transformDialectVectorCantonScores()">
											<td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{ region.key }}</td>
											<td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ region.value }}</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<app-region-matcher-view *ngIf="regionMatcherVisible" (closeRegionalMatcher)="closeRegionalMatcher()" class=" flex flex-col flex-grow"></app-region-matcher-view>
		</ng-container>
	
	</div>
</main>
