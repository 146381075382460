<main class="w-full p-8 border-t-4 border-green-400 bg-white dark:bg-gray-900 overflow-hidden overflow-y-scroll">
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8 w-full mx-auto max-w-7xl">
		<div class="min-w-0 flex-1">
			<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">Regional Capture Data</h2>
		</div>
		<div class="mt-4 flex md:ml-4 md:mt-0">
			<p>Work in Progress...</p>
		</div>
	</header>
</main>
