import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'newline',
    standalone: true
})
export class NewlinePipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): string {
        // Check if the input value is a string
        if (typeof value === 'string') {
            // Replace all occurrences of newline characters with HTML <br> tags
            return value.replace(/\n/g, '<br>');
        }
        // If the value is not a string, return it as is or convert it to a string if necessary
        return value ? value.toString() : '';
    }

}
