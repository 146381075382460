import {Component, ElementRef, ViewChild} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-motivational-player',
    standalone: true,
    imports: [
        NgIf
    ],
    templateUrl: './motivational-player.component.html',
    styleUrl: './motivational-player.component.css'
})
export class MotivationalPlayerComponent {
    @ViewChild('videoPlayer', {static: true}) videoPlayer!: ElementRef<HTMLVideoElement>;
    showVideo: boolean = false;

    constructor() {
    }

    openVideo(): void {
        this.showVideo = true;
        this.videoPlayer.nativeElement.play();
    }

    onVideoEnd(): void {
        this.showVideo = false;
    }
}
