import {Component} from '@angular/core';
import {NgForOf} from '@angular/common';
import {RouterLink} from '@angular/router';
import {User} from '../../interfaces/User.interface';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-users',
    standalone: true,
    imports: [
        NgForOf,
        RouterLink
    ],
    templateUrl: './users.component.html',
    styleUrl: './users.component.css'
})
export class UsersComponent {

    users: User[] = []

    constructor(private userService: UserService) {
        this.userService.getAllUsers().subscribe((users) => {
            this.users = users;
        });
    }

}
