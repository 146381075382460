import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {environment} from '../../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HttpClientModule} from '@angular/common/http';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        importProvidersFrom([
            BrowserAnimationsModule,
            AngularFireModule.initializeApp(environment.firebase),
            AngularFireAuthModule,
            HttpClientModule
        ]), provideAnimationsAsync(),
    ]
};
