<main class="w-full p-8 flex flex-col border-t-4 bg-white dark:bg-gray-900" [ngClass]="{
        'border-blue-400': textBundleType === TextBundleType.Base
      }">
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8 w-full mx-auto max-w-7xl">
		<div class="min-w-0 flex-1">
			<ng-container *ngIf="textBundleType === TextBundleType.Base">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">{{ baseTextBundle?.name }}</h2>
				<h2 class="text-5xl font-normal text-gray-900 dark:text-white sm:truncate sm:text-lg sm:tracking-tight">{{ 'ID:  ' + baseTextBundle?.id }}</h2>
				<p class="mt-2 text-sm text-gray-700 dark:text-gray-300 mr-10">{{ baseTextBundle?.description }}</p>
			</ng-container>
		</div>
		<div class="mt-4 flex md:ml-4 md:mt-0">
			<button (click)="goBack()" type="button" class="inline-flex items-center rounded-md bg-gray-200 dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-300 dark:hover:bg-white/20">
				Exit
				<span class="inline-flex items-center bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded px-1 ml-2">
                    esc
                </span></button>
		</div>
	</header>
	
	<div class="flex-grow overflow-y-scroll" #wrapperElement>
		<div class="bg-white dark:bg-gray-900 ">
			<div class="mx-auto max-w-7xl">
				<div class="bg-white dark:bg-gray-900">
					<div class="">
						<div class="flex items-end justify-items-end">
							<div class="sm:flex-auto">
								<h1 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">Dialect Snippets</h1>
								<p class="mt-2 text-sm text-gray-700 dark:text-gray-300">Entities include a text which participants will record captures of.</p>
							</div>
							<div class="mt-4 sm:mt-0 sm:flex-none flex flex-row justify-items-end gap-4">
								<div>
									<div class="relative flex items-center min-w-[250px]">
										<input placeholder="Snippet ID Search" [(ngModel)]="quickSearch" type="text" name="search" id="search" class="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6">
										<div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
											<kbd class="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">⌘K</kbd>
										</div>
									</div>
								</div>
								<a *ngIf="textBundleType === TextBundleType.Base" class="block rounded-md bg-sky-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" routerLink="/app/base-text-bundles/entity-editor" [queryParams]="{baseTextBundleID: baseTextBundleID}">Open
									Entity Creator
								</a>
							</div>
						</div>
						<div #tableElement class="mt-8 flow-root">
							<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
									<table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
										<thead>
										<tr>
											<th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0" scope="col">ID</th>
											<th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white" scope="col">Text</th>
											<th *ngIf="textBundleType === TextBundleType.Regional" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white" scope="col">Translation</th>
											<th class="relative py-3.5 pl-3 pr-4 sm:pr-0" scope="col">
												<span class="sr-only">Edit</span>
											</th>
										</tr>
										</thead>
										<tbody class="divide-y divide-gray-200 dark:divide-gray-800">
										<tr *ngFor="let textSnippet of textSnippets">
											<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-0">{{ textSnippet.id }}</td>
											<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 dark:text-gray-300 truncate max-w-[200px]">{{ textSnippet.text }}</td>
											<td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
												<a *ngIf="textBundleType === TextBundleType.Base" (click)="navigateToEntityEditor(baseTextBundleID, textSnippet?.id)" class="text-blue-600 dark:text-sky-400 hover:text-blue-500 dark:hover:text-sky-300">Edit<span class="sr-only">, Lindsay Walton</span></a>											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
