import {Component, OnDestroy, OnInit} from '@angular/core';
import {FirebaseFunctionService} from '../../services/firebase-function.service';
import {NgForOf} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {CaptureEventService} from '../../services/capture-event.service';
import {interval, Subscription, switchMap, takeUntil, tap} from 'rxjs';

export interface UserRankingType {
    score: number;
    user_id: string;
    user_name: string;
}

@Component({
    selector: 'app-capture-event-ranking',
    standalone: true,
    imports: [
        NgForOf
    ],
    templateUrl: './capture-event-ranking.component.html',
    styleUrls: ['./capture-event-ranking.component.css']
})
export class CaptureEventRankingComponent implements OnInit, OnDestroy {
    userRanking: UserRankingType[] = [];
    captureEventID!: string;
    captureEventName!: string;
    private rankingRefreshSubscription!: Subscription;

    constructor(private firebaseFunctionsService: FirebaseFunctionService, private route: ActivatedRoute, private captureEventService: CaptureEventService) {}

    ngOnInit() {
        this.captureEventID = this.route.snapshot.paramMap.get('captureEventID') as string;

        this.captureEventService.getCaptureEvent(this.captureEventID).subscribe((event) => {
            this.captureEventName = event.name;
        });

        // Fetch initial ranking immediately
        this.fetchRanking();

        const fiveMinutes = 300000;
        const fiftySeconds = 50000;
        this.rankingRefreshSubscription = interval(fiftySeconds).subscribe({
            next: () => {
                this.fetchRanking();
            }
        });
    }

    fetchRanking() {
        this.firebaseFunctionsService.getRankingOfCaptureEvent(this.captureEventID).subscribe({
            next: (response) => {
                console.log(response);
                if (response && Array.isArray(response["ranking"])) {
                    this.userRanking = response["ranking"];
                }
            },
            error: (error) => console.error('Error fetching rankings:', error)
        });
    }

    ngOnDestroy() {
        // Unsubscribe from the interval when the component is destroyed
        if (this.rankingRefreshSubscription) {
            this.rankingRefreshSubscription.unsubscribe();
        }
    }
}
