import {inject, Injectable} from '@angular/core';
import {Auth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup} from '@angular/fire/auth';
import {Router} from '@angular/router';

interface User {
    uid: string;
    email: string | null;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private auth = inject(Auth);
    provider = new GoogleAuthProvider();

    constructor(private router: Router) {
    }

    logOutUser() {
        return this.auth.signOut().then(() => {
            return this.router.navigate(['/login']);
        })
    }

    redirectToApp() {
        this.router.navigate(['/app']);
    }

    async logInUsingGoogle() {
        try {
            const userCreds = await signInWithPopup(this.auth, this.provider);
            const idTokenResult = await userCreds.user.getIdTokenResult();
            if (this.isAuthorized(idTokenResult.claims)) {
                return true;
            } else {
                throw new Error('Unauthorized: Only admins or superadmins can access');
                return false
            }
        } catch (error) {
            console.error('Login failed:', error);
            this.router.navigate(['/login']);
            return false
        }
    }

    logInUsingCredentials(email: string, password: string) {
        signInWithEmailAndPassword(this.auth, email, password).then((userCreds) => {
            this.redirectToApp()
        })
    }

    getUserName(): string {
        const user = this.auth.currentUser;
        if (user) {
            return user.displayName || '';
        }
        return '';
    }

    getUserImage(): string {
        const user = this.auth.currentUser;
        if (user) {
            return user.photoURL || '';
        }
        return '';
    }

    private isAuthorized(claims: any): boolean {
        if (claims.email === "yaron.fanger@ylabs.ch"){
            return true;
        }

        return claims.role === 'admin' || claims.role === 'superadmin';
    }
}
