import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {BaseTextBundle, RegionalTextBundle, TextBundleType} from '../../interfaces/TextBundle.interface';
import {Location, NgClass, NgForOf, NgIf} from '@angular/common';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {TextSnippet} from '../../interfaces/TextSnippet.interface';
import {TextSnippetService} from '../../services/text-snippet.service';
import {TextBundlesService} from '../../services/text-bundles.service';
import {FormsModule} from '@angular/forms';
import {AlertService} from '../../services/alert.service';

@Component({
    selector: 'app-text-bundle-view',
    standalone: true,
    imports: [RouterLink, NgForOf, NgIf, NgClass, FormsModule],
    templateUrl: './text-bundle-view.component.html',
    styleUrl: './text-bundle-view.component.css',
})
export class TextBundleViewComponent implements OnInit, AfterViewInit {
    @ViewChild('tableElement') tableElement!: ElementRef;
    @ViewChild("wrapperElement") wrapperElement!: ElementRef;

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.goBack()
    }

    @HostListener('document:keydown', ['$event'])
    onQuickOpen(event: KeyboardEvent) {
        if (event.metaKey && event.key === 'k') {
            event.preventDefault();
            this.handleCmdK();
        }
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + window.innerHeight;
        let max = document.documentElement.scrollHeight;

        const tolerance = 10; // pixels

        if (pos + tolerance >= max) {
            this.updateItems();
        }
    }

    // auto load
    loadedQuote = 0;
    loadQuote = 30;

    baseTextBundleID = ""
    textBundleType = TextBundleType.Base;
    baseTextBundle!: BaseTextBundle
    textSnippets: TextSnippet[] = [];
    snippetReferences: DocumentReference[] = [];

    quickSearch = "";

    protected readonly TextBundleType = TextBundleType;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private textSnippetService: TextSnippetService,
        private textBundleService: TextBundlesService,
        private router: Router,
        private alertService: AlertService
    ) {
    }

    ngOnInit() {
        this.checkUrlPathState();

        this.baseTextBundleID = this.route.snapshot.paramMap.get('baseTextBundleID') as string;

        this.textBundleService.getBaseTextBundleData(this.baseTextBundleID).subscribe((baseTextBundle) => {
            this.baseTextBundle = baseTextBundle;
        });

        this.textSnippetService.getTextSnippetsReferences(this.baseTextBundleID).subscribe((refs) => {
            this.snippetReferences = refs;
            this.loadMoreItems();
        });
    }


    handleCmdK() {

        this.quickSearch = this.quickSearch.trim()

        if (this.quickSearch.length != 20) {
            this.alertService.showAlert('Lou....', 'Text snippet ID must be 20 characters long');
        } else {
            this.textSnippetService.checkIfTextSnippetExists(this.quickSearch, this.baseTextBundleID).subscribe((exists) => {
                if (exists) {
                    this.navigateToEntityEditor(this.baseTextBundleID, this.quickSearch);
                    this.quickSearch = "";
                } else {
                    this.alertService.showAlert('Lou....', 'Text snippet not found');
                }
            });
        }
    }

    updateItems(): void {
        this.loadedQuote = this.loadQuote;
        this.loadQuote += 10;
        this.loadMoreItems();
    }

    loadMoreItems(): void {
        let start = this.loadedQuote;
        for (let i = start; i < this.loadQuote; i++) {
            const snippetRef = this.snippetReferences.shift();
            if (snippetRef) {
                this.textSnippetService.getTextSnippetByDocRef(snippetRef).subscribe(snippet => {
                    this.textSnippets.push(snippet);
                });
            }
        }
    }

    ngAfterViewInit(): void {
        this.wrapperElement.nativeElement.style.maxHeight = this.wrapperElement.nativeElement.clientHeight + 'px';
        this.wrapperElement.nativeElement.addEventListener('scroll', this.onTableScroll.bind(this));
    }

    checkUrlPathState(): void {
        const fullUrl = this.route.snapshot.url.join('/');
        const rootPath = fullUrl.split('/')[0];

        if (rootPath === 'base-text-bundles') {
            this.textBundleType = TextBundleType.Base;
        } else if (rootPath === 'regional-text-bundles') {
            this.textBundleType = TextBundleType.Regional;
        }
    }

    onTableScroll(): void {
        const div = this.wrapperElement.nativeElement;
        const isAtBottom = div.scrollHeight - div.scrollTop === div.clientHeight;

        if (isAtBottom) {
            this.updateItems()
        }
    }

    navigateToEntityEditor(baseTextBundleID: string, textSnippetID: string | undefined): void {
        this.router.navigate(['/app/base-text-bundles/entity-editor'], {
            queryParams: { baseTextBundleID, textSnippetID }
        });
    }

    goBack() {
        this.location.back();
    }
}
