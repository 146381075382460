import {Component} from '@angular/core';
import {CaptureEventViewComponent} from '../../components/capture-event-view/capture-event-view.component';
import {NgForOf, NgIf} from '@angular/common';
import {TextBundleType} from '../../interfaces/TextBundle.interface';
import {RouterLink} from '@angular/router';
import {CaptureEvent} from '../../interfaces/CaptureEvent.interface';
import {CaptureEventService} from '../../services/capture-event.service';

@Component({
    selector: 'app-capture-events',
    standalone: true,
    imports: [
        CaptureEventViewComponent,
        NgForOf,
        NgIf,
        RouterLink
    ],
    templateUrl: './capture-events.component.html',
    styleUrl: './capture-events.component.css'
})
export class CaptureEventsComponent {

    captureEvents: CaptureEvent[] = [];

    constructor(private captureEventService: CaptureEventService) {
        this.captureEventService.getAllCaptureEvents().subscribe((events) => {
            this.captureEvents = events;
        });
    }
}
