<div class="absolute z-10 top-0 right-0 left-0 bottom-0" role="dialog" aria-modal="true" *ngIf="showModal">
	<div [@backdrop] class="absolute inset-0 bg-black backdrop-blur bg-opacity-10 transition-opacity"></div>
	
	<div class="relative overflow-y-auto h-full content-center">
		<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
			<div [@modal]="showModal ? 'active' : 'void'" class="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
				<div class="sm:flex sm:items-start">
					<div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-700 sm:mx-0 sm:h-10 sm:w-10">
						<svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
						</svg>
					</div>
					<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
						<h3 class="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-200" id="modal-title">Delete TextSnippet <span class="font-thin">{{ textSnippetID }}</span></h3>
						<div class="mt-2">
							<p class="text-sm text-gray-700 dark:text-gray-400">All localizedData will be deleted permanently, this cannot be undone. </p>
						</div>
					</div>
				</div>
				<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
					<button (click)="deactivateAccount()" type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">
						Delete Permanently
					</button>
					<button (click)="hideDeactivationModal()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-gray-300 dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-400 dark:hover:bg-gray-600 sm:mt-0 sm:w-auto">
						Cancel
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
