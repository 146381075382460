import {inject, Injectable} from '@angular/core';
import {collection, collectionData, doc, docData, Firestore, getDocs, query, runTransaction, Transaction, where} from '@angular/fire/firestore';
import {combineLatest, firstValueFrom, forkJoin, from, map, Observable, of, switchMap, tap} from 'rxjs';
import {CaptureEvent} from '../interfaces/CaptureEvent.interface';
import {TextBundlesService} from './text-bundles.service';
import {UserService} from './user.service';
import {ThumbnailService} from './thumbnail.service';
import {DialectCaptureInterface} from '../interfaces/DialectCapture.interface';
import {TextSnippet} from '../interfaces/TextSnippet.interface';


@Injectable({
    providedIn: 'root'
})
export class CaptureEventService {
    private firestore: Firestore = inject(Firestore);
    private captureEventRootPath = 'CaptureEvents';

    constructor(
        private textBundleService: TextBundlesService,
        private userService: UserService,
        private thumbnailService: ThumbnailService
    ) {
    }

    getRef(captureEventId: string) {
        return doc(this.firestore, `${this.captureEventRootPath}/${captureEventId}`);
    }

    getNewRef() {
        return doc(collection(this.firestore, this.captureEventRootPath))
    }

    async create(captureEvent: CaptureEvent, appThumb: File, webThumb: File) {
        const thumbnailUrl = await firstValueFrom(this.thumbnailService.uploadFile(appThumb));
        const thumbnailUrlWeb = await firstValueFrom(this.thumbnailService.uploadFileWeb(webThumb));

        console.log(captureEvent);

        await runTransaction(this.firestore, async (transaction) => {
            const baseTextBundleRef = this.textBundleService.getBaseTextBundleRef(captureEvent.originBaseTextBundle.id);
            const captureEventRef = this.getNewRef();

            let captureEventDoc = {
                id: captureEventRef.id,
                name: captureEvent.name,
                description: captureEvent.description,
                originBaseTextBundle: baseTextBundleRef,
                thumbnail: thumbnailUrl,
                webThumbnail: thumbnailUrlWeb,
                isPublicEvent: captureEvent.isPublicEvent,
                eventDate: captureEvent.eventDate,
                eventDescription: captureEvent.eventDescription ?? "",
                calendlyLink: captureEvent.calendlyLink,
                placeId: captureEvent.placeId,
                placeDescription: captureEvent.placeDescription,
            };

            console.log("finished composing and uploaded");

            const sourceCollectionPath = `BaseTextBundles/${captureEvent.originBaseTextBundle.id}/TextSnippets`;
            const targetCollectionPath = `CaptureEvents/${captureEventRef.id}/TextSnippets`;
            await this.copyTextSnippetCollectionToCaptureEvent(transaction, sourceCollectionPath, targetCollectionPath);

            console.log("finished copying text snippets");
            console.log("id: " + captureEventRef.id);

            transaction.set(captureEventRef, captureEventDoc);

            console.log("finished setting capture event");
            return true;
        });
    }

    async updateWebThumbnailOfCaptureEvent(captureEventId: string, newFile: File) {
        const newThumbnailUrl = await firstValueFrom(this.thumbnailService.uploadFileWeb(newFile));

        await runTransaction(this.firestore, async (transaction) => {
            const captureEventRef = this.getRef(captureEventId);

            const captureEventDocSnapshot = await transaction.get(captureEventRef);
            if (!captureEventDocSnapshot.exists) {
                throw new Error(`Capture event with ID ${captureEventId} does not exist.`);
            }

            console.log("finished updating thumbnail");

            // Update the thumbnail field in the document
            transaction.update(captureEventRef, {webThumbnail: newThumbnailUrl});

            console.log("finished setting updated capture event");
            return true;
        });
    }

    async updateThumbnailOfCaptureEvent(captureEventId: string, newFile: File) {
        const newThumbnailUrl = await firstValueFrom(this.thumbnailService.uploadFile(newFile));

        await runTransaction(this.firestore, async (transaction) => {
            const captureEventRef = this.getRef(captureEventId);

            const captureEventDocSnapshot = await transaction.get(captureEventRef);
            if (!captureEventDocSnapshot.exists) {
                throw new Error(`Capture event with ID ${captureEventId} does not exist.`);
            }

            console.log("finished updating thumbnail");

            // Update the thumbnail field in the document
            transaction.update(captureEventRef, {thumbnail: newThumbnailUrl});

            console.log("finished setting updated capture event");
            return true;
        });
    }


    getAllCaptureEvents(): Observable<CaptureEvent[]> {
        return collectionData(collection(this.firestore, this.captureEventRootPath), {idField: 'doc_id'}) as Observable<CaptureEvent[]>;
    }

    getCaptureEvent(id: string): Observable<CaptureEvent> {
        return docData(doc(this.firestore, `${this.captureEventRootPath}/${id}`)) as Observable<CaptureEvent>;
    }

    getEnrolledUsers(captureEventId: string): Observable<string[]> {
        const enrolledUsersCollectionPath = `CaptureEvents/${captureEventId}/EnrolledUsers`;
        const collectionRef = collection(this.firestore, enrolledUsersCollectionPath);
        return from(getDocs(collectionRef)).pipe(
            map(querySnapshot => querySnapshot.docs.map(doc => doc.id))
        );
    }

    getCaptureEventsForUser(userId: string): Observable<CaptureEvent[]> {
        const captureEventsCollectionRef = collection(this.firestore, this.captureEventRootPath);

        return from(getDocs(captureEventsCollectionRef)).pipe(
            switchMap(querySnapshot => {
                const captureEvents = querySnapshot.docs.map(doc => {
                    const captureEvent = doc.data() as CaptureEvent;
                    captureEvent.id = doc.id;
                    return captureEvent;
                });

                if (captureEvents.length === 0) {
                    return of([]); // Return an empty array if no capture events are found
                }

                const enrolledUsersChecks$ = captureEvents.map(captureEvent => {
                    const enrolledUsersCollectionPath = `CaptureEvents/${captureEvent.id}/EnrolledUsers`;
                    const enrolledUsersCollectionRef = collection(this.firestore, enrolledUsersCollectionPath);

                    return from(getDocs(enrolledUsersCollectionRef)).pipe(
                        map(querySnapshot => {
                            const isEnrolled = querySnapshot.docs.some(enrolledUserDoc => enrolledUserDoc.id === userId);
                            return isEnrolled ? captureEvent : null;
                        })
                    );
                });

                return combineLatest(enrolledUsersChecks$).pipe(
                    map(enrolledEvents => enrolledEvents.filter((event): event is CaptureEvent => event !== null))
                );
            }),
            map(captureEvents => captureEvents.length > 0 ? captureEvents : [])
        );
    }

    queryCaptureEvents(matchString: string): Observable<CaptureEvent[]> {
        const usersCollection = collection(this.firestore, this.captureEventRootPath);
        const queries = [
            query(usersCollection, where('name', '==', matchString)),
            query(usersCollection, where('id', '==', matchString)),
            query(usersCollection, where('postcodes', 'array-contains', matchString)),
            query(usersCollection, where('region_name', '==', matchString)),
            query(usersCollection, where('country', '==', matchString))
        ];

        const observables = queries.map(q => from(getDocs(q)).pipe(
            map(querySnapshot => querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}) as CaptureEvent))
        ));

        return forkJoin(observables).pipe(
            map(results => {
                const combined = results.reduce((acc, val) => acc.concat(val), [] as CaptureEvent[]);
                return combined.filter((user, index, self) =>
                    index === self.findIndex(t => t.id === user.id));
            })
        );
    }

    enrollUserInCaptureEvent(captureEventId: string, userId: string): Observable<void> {
        const captureEventRef = this.getRef(captureEventId);
        const userRef = this.userService.getRef(userId);
        const enrolledUsersCollectionRef = collection(this.firestore, captureEventRef.path + '/EnrolledUsers');

        return from(runTransaction(this.firestore, transaction => {
            return Promise.all([
                transaction.get(captureEventRef),
                transaction.get(userRef)
            ]).then(([captureEventSnapshot, userSnapshot]) => {
                if (!captureEventSnapshot.exists()) {
                    throw new Error(`CaptureEvent with ID ${captureEventId} does not exist.`);
                }
                if (!userSnapshot.exists()) {
                    throw new Error(`User with ID ${userId} does not exist.`);
                }

                const userDocRef = doc(enrolledUsersCollectionRef, userRef.id);
                transaction.set(userDocRef, {});
            });
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed: Enrolled user with ID ${userId} in CaptureEvent with ID ${captureEventId}`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }

    removeUserFromCaptureEvent(captureEventId: string, userId: string): Observable<void> {
        const captureEventRef = this.getRef(captureEventId);
        const userRef = this.userService.getRef(userId);
        const enrolledUsersCollectionRef = collection(this.firestore, captureEventRef.path + '/EnrolledUsers');

        return from(runTransaction(this.firestore, transaction => {
            return Promise.all([
                transaction.get(captureEventRef),
                transaction.get(userRef)
            ]).then(([captureEventSnapshot, userSnapshot]) => {
                if (!captureEventSnapshot.exists()) {
                    throw new Error(`CaptureEvent with ID ${captureEventId} does not exist.`);
                }
                if (!userSnapshot.exists()) {
                    throw new Error(`User with ID ${userId} does not exist.`);
                }

                const userDocRef = doc(enrolledUsersCollectionRef, userRef.id);
                transaction.delete(userDocRef);
            });
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed: Removed user with ID ${userId} from CaptureEvent with ID ${captureEventId}`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }

    getCapturesByEvent(captureEventId: string): Observable<DialectCaptureInterface[]> {
        const captureEventRef = this.getRef(captureEventId)
        const capturesCollectionRef = collection(this.firestore, 'DialectCaptures');
        const capturesQuery = query(capturesCollectionRef, where('captureEventRef', '==', captureEventRef));

        return collectionData(capturesQuery, {idField: 'id'}).pipe(
            map(captures => captures.map(capture => capture as DialectCaptureInterface))
        );
    }

    getCaptureCountByEvent(captureEventId: string): Observable<number> {
        const captureEventRef = this.getRef(captureEventId)
        const capturesCollectionRef = collection(this.firestore, 'DialectCaptures');
        const capturesQuery = query(capturesCollectionRef, where('captureEventRef', '==', captureEventRef));

        return from(getDocs(capturesQuery)).pipe(
            map(querySnapshot => querySnapshot.size)
        );
    }

    getCaptureEventAssociatedWithBaseTextBundle(baseTextBundleId: string): Observable<CaptureEvent[] | undefined> {
        const captureEventsRef = collection(this.firestore, this.captureEventRootPath);
        const baseTextBundleRef = this.textBundleService.getBaseTextBundleRef(baseTextBundleId);

        return collectionData(captureEventsRef, { idField: 'id' }).pipe(
            map(captureEvents => captureEvents as CaptureEvent[]),
            map(captureEvents => {
                const filteredEvents = captureEvents.filter(event => event.originBaseTextBundle.id === baseTextBundleRef.id);
                return filteredEvents.length > 0 ? filteredEvents : undefined;
            })
        );
    }
    

    private async copyTextSnippetCollectionToCaptureEvent(transaction: Transaction, sourceCollectionPath: string, targetCollectionPath: string) {
        const sourceDocsSnapshot = await getDocs(collection(this.firestore, sourceCollectionPath));

        sourceDocsSnapshot.docs.forEach((documentSnapshot) => {
            const docData = documentSnapshot.data();
            const newData = {...docData, origin_id: documentSnapshot.id};
            const newDocRef = doc(this.firestore, targetCollectionPath, documentSnapshot.id);
            transaction.set(newDocRef, newData);
        });
    }

    updateCaptureEventTextSnippet(captureEventId: string, textSnippetId: string, updatedValue: string) {
        const path = this.getRef(captureEventId).path + '/TextSnippets';
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return from(runTransaction(this.firestore, transaction => {
            return transaction.get(snippetRef).then(snippetDoc => {
                if (!snippetDoc.exists()) {
                    throw new Error(`TextSnippet with ID ${textSnippetId} does not exist in BaseTextBundle with ID ${captureEventId}.`);
                }

                transaction.update(snippetRef, {text: updatedValue});
            });
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed: Updated TextSnippet with ID ${textSnippetId} in BaseTextBundle with ID ${captureEventId}`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }

    getCaptureEventTextSnippet(captureEventId: string, textSnippetId: string) {
        const path = this.getRef(captureEventId).path + '/TextSnippets';
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return docData(snippetRef) as Observable<TextSnippet>;
    }
}
