import {Component, OnInit} from '@angular/core';
import {NgForOf} from '@angular/common';
import {RouterLink} from '@angular/router';
import {RegionalTextBundle} from '../../interfaces/TextBundle.interface';
import {TextBundlesService} from '../../services/text-bundles.service';

@Component({
    selector: 'app-regional-text-bundles',
    standalone: true,
    imports: [
        NgForOf,
        RouterLink
    ],
    templateUrl: './regional-text-bundles.component.html',
    styleUrl: './regional-text-bundles.component.css'
})
export class RegionalTextBundlesComponent implements OnInit {
    public regionalTextBundles: RegionalTextBundle[] = [];

    constructor(private textBundleService: TextBundlesService) {
    }

    ngOnInit() {
        this.textBundleService.getAllRegionalTextBundlesFromAllBase().subscribe(regionalTextBundles => {
            this.regionalTextBundles = regionalTextBundles;
        });
    }
}
