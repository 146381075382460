import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {BaseTextBundle, RegionalTextBundle} from '../../interfaces/TextBundle.interface';
import {TextSnippet} from '../../interfaces/TextSnippet.interface';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {Location, NgClass, NgForOf, NgIf} from '@angular/common';
import {TextBundlesService} from '../../services/text-bundles.service';
import {TextSnippetService} from '../../services/text-snippet.service';
import {DeleteTextSnippetModal} from '../base-mode-delete-modal/delete-text-snippet-modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CaptureEventService} from '../../services/capture-event.service';
import {CaptureEvent} from '../../interfaces/CaptureEvent.interface';

@Component({
    selector: 'app-base-entity-editor',
    standalone: true,
    imports: [
        DeleteTextSnippetModal,
        FormsModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        NgClass,
        RouterLink
    ],
    templateUrl: './base-entity-editor.component.html',
    styleUrl: './base-entity-editor.component.css'
})
export class BaseEntityEditorComponent implements OnInit {
    dropDownMenuVisible: boolean = false;
    textFieldEdited: boolean = false;
    deleteModelOpen = false
    textSnippetEditLocked: boolean = false;

    textSnippetReferenceIndex: number = 0;
    textSnippetReferences: DocumentReference[] = [];
    textBaseBundle!: BaseTextBundle;
    textSnippet!: TextSnippet;
    regionalTextBundles: RegionalTextBundle[] = [];
    associatedCaptureEvents: CaptureEvent[] = [];
    originalTextSnippetValue: string = "";
    textSnippetValue: string = "";
    selectedCaptureEvent: CaptureEvent | null = null;
    captureEventSpecificTextSnippet: TextSnippet | null = null;


    baseTextBundleID: string = "";
    textSnippetID: string = "";

    @ViewChild('menu') menuElement!: ElementRef;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey(event: KeyboardEvent): void {
        this.location.back();
    }

    @HostListener('document:keydown.meta.ArrowLeft', ['$event'])
    handleLeftArrowKey(event: KeyboardEvent): void {
        event.preventDefault();

        this.navigateBack();
    }

    @HostListener('document:keydown.meta.ArrowRight', ['$event'])
    handleRightArrowKey(event: KeyboardEvent): void {
        event.preventDefault();

        this.navigateForward()
    }

    @HostListener('keydown.meta.enter', ['$event'])
    handleCommandEnterKey(event: KeyboardEvent): void {
        console.log('Command + Enter');
        this.saveTextSnippet();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private textBundleService: TextBundlesService,
        private textSnippetService: TextSnippetService,
        private captureEventService: CaptureEventService
    ) {
    }

    ngOnInit(): void {
        this.loadRouteParams();
        this.loadData();

        console.log('Base Text Bundle ID: ', this.baseTextBundleID);

        // TODO: store subscriber only have one subscriber
        this.captureEventService.getCaptureEventAssociatedWithBaseTextBundle(this.baseTextBundleID).subscribe((captureEvents) => {
            if (captureEvents) {
                this.associatedCaptureEvents = captureEvents;
            }
            console.log('Capture Event: ', captureEvents);
        });
    }

    navigateBack() {
        if (this.textSnippetReferenceIndex > 0) {
            this.clearCaptureEvent();
            this.textSnippetReferenceIndex--;
            this.loadTextSnippet();
        }
    }

    navigateForward() {
        if (this.textSnippetReferenceIndex < this.textSnippetReferences.length - 1) {
            this.clearCaptureEvent();
            this.textSnippetReferenceIndex++;
            this.loadTextSnippet();
        }
    }

    clearCaptureEvent() {
        this.selectedCaptureEvent = null;
        this.captureEventSpecificTextSnippet = null;
    }

    toggleDropDownMenu(): void {
        this.dropDownMenuVisible = !this.dropDownMenuVisible;
    }

    showDeleteModal() {
        this.deleteModelOpen = true;
    }

    navigateToRegion(region: any): void {
        const queryParams = {
            regionalTextBundleID: region.id,
            baseTextBundleID: this.baseTextBundleID,
            textSnippetID: this.textSnippet.id,
        };

        this.router.navigate(["app", "regional-text-bundles", 'entity-editor'], {queryParams}).then(() => {
            this.loadRouteParams();
            this.toggleDropDownMenu();
        });
    }

    discardTranslationChanges(): void {
        this.textSnippetValue = this.originalTextSnippetValue;
        this.textFieldEdited = false;
    }

    deleteTextSnippet() {
        this.textSnippetService.deleteTextSnippet(this.textSnippet.id, this.baseTextBundleID).subscribe(() => {
            console.log('TextSnippet deleted');
        });
    }

    duplicateTextSnippet() {
        this.textSnippetService.duplicateTextSnippet(this.textSnippet.id, this.baseTextBundleID).subscribe((doucmentID) => {
            console.log(`TextSnippet duplicated with ID: ${doucmentID}`);
        });
    }

    saveTextSnippet() {
        if (this.selectedCaptureEvent) {
            if (this.selectedCaptureEvent.id != null) {
                this.captureEventService.updateCaptureEventTextSnippet(this.selectedCaptureEvent.id, this.textSnippet.id, this.textSnippetValue).subscribe(() => {
                    this.textFieldEdited = false;
                });
            }
        } else {
            if (!this.textSnippetEditLocked) {
                this.textSnippetService.updateBaseTextSnippet(this.textSnippet.id, this.baseTextBundleID, this.textSnippetValue).subscribe(() => {
                    this.loadTextSnippet();
                    this.textFieldEdited = false;
                });
            }
        }
    }

    loadTextSnippetFromCaptureEvent() {
        if (this.selectedCaptureEvent && this.selectedCaptureEvent.id != null) {
            this.captureEventService.getCaptureEventTextSnippet(this.selectedCaptureEvent.id, this.textSnippet.id).subscribe((textSnippet) => {
                this.captureEventSpecificTextSnippet = textSnippet;
                this.textSnippetValue = textSnippet.text;
                this.originalTextSnippetValue = textSnippet.text;
            });
        } else {
            console.log('Capture Event not selected, cannot load text snippet');
        }
    }

    onTextInput() {
        this.textFieldEdited = this.textSnippetValue !== this.originalTextSnippetValue;
    }

    private loadData() {
        this.loadBaseTextBundle();
        this.loadTextSnippetsReferences();
        this.loadRegionalTextBundle();
    }

    private loadRouteParams(): void {
        this.route.queryParams.subscribe(params => {
            this.baseTextBundleID = params['baseTextBundleID'];
            this.textSnippetID = params['textSnippetID'];
        });
    }

    private loadBaseTextBundle(): void {
        this.textBundleService.getBaseTextBundleData(this.baseTextBundleID).subscribe((baseTextBundle) => {
            this.textBaseBundle = baseTextBundle;
        });
    }

    private loadRegionalTextBundle(): void {
        this.textBundleService.getAllRegionalTextBundlesData(this.baseTextBundleID).subscribe((regionalTextBundles) => {
            this.regionalTextBundles = regionalTextBundles;
        });
    }

    private loadTextSnippetsReferences(): void {
        this.textSnippetService.getTextSnippetsReferences(this.baseTextBundleID).subscribe((textSnippetReferences) => {
            this.textSnippetReferences = textSnippetReferences;
            this.loadTextSnippet(true);
        });
    }

    private loadTextSnippet(initial: boolean = false): void {
        this.textFieldEdited = false;
        if (initial && this.textSnippetID) {
            this.textSnippetReferenceIndex = this.findIndexOfTextSnippetBasedOnID(this.textSnippetID);
        }

        this.textSnippetService.getTextSnippetByDocRef(this.textSnippetReferences[this.textSnippetReferenceIndex]).subscribe((textSnippet) => {
            this.textSnippet = textSnippet;
            this.textSnippetValue = textSnippet.text;
            this.originalTextSnippetValue = textSnippet.text;

            if (this.textSnippet.localized_content === null) {
                this.textSnippetEditLocked = false;
            } else if (Object.keys(this.textSnippet.localized_content).length > 0) {
                this.textSnippetEditLocked = true;
            }
        });
    }

    private findIndexOfTextSnippetBasedOnID(textSnippetId: string): number {
        return this.textSnippetReferences.findIndex(value => value.id === textSnippetId);
    }

    protected readonly Object = Object;

    activeCaptureEventTextSnippet(captureEvent: CaptureEvent) {
        this.selectedCaptureEvent = captureEvent;
        this.dropDownMenuVisible = false;
        this.loadTextSnippetFromCaptureEvent();
    }

    resetCaptureEvent() {
        this.selectedCaptureEvent = null;
        this.dropDownMenuVisible = false;
        this.loadTextSnippet();
    }
}
