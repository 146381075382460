import {Component, HostListener, OnInit} from '@angular/core';
import {DatePipe, Location, NgClass, NgForOf, NgIf, PercentPipe} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {User, UserRegionData} from '../../interfaces/User.interface';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {CaptureEvent} from '../../interfaces/CaptureEvent.interface';
import {SearchComponent} from '../search/search.component';
import {DeactivationModalComponent} from '../modal/deactivation-modal.component';
import {UserService} from '../../services/user.service';
import {CaptureEventService} from '../../services/capture-event.service';
import {FirebaseFunctionService} from '../../services/firebase-function.service';
import {RegionMatcherViewComponent} from '../region-matcher-view/region-matcher-view.component';
import {DialectMatrixService} from '../../services/dialect-matrix.service';

@Component({
    selector: 'app-user-view',
    standalone: true,
    imports: [
        NgForOf,
        ReactiveFormsModule,
        DatePipe,
        NgIf,
        RouterLink,
        NgClass,
        SearchComponent,
        DeactivationModalComponent,
        RegionMatcherViewComponent,
        PercentPipe
    ],
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {

    form!: FormGroup;
    userID: string = "";
    user: User | undefined;
    currentView: "userData" | "userEnrolledCaptureEvents" | "management" | "region" = "region";
    searchOpen: boolean = false;
    deactivationModalVisible: boolean = false;
    userEnrolledCaptureEvents: CaptureEvent[] = [];
    regionMatcherVisible: boolean = false;
    regionData!: UserRegionData;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private userService: UserService,
        private captureEventService: CaptureEventService,
        private firebaseFunctionService: FirebaseFunctionService,
        private dialectMatrixService: DialectMatrixService
    ) {
        const id = this.route.snapshot.paramMap.get('userID');
        if (id) {
            this.userID = id;
            this.form = new FormGroup({
                userRole: new FormControl("none")
            });

            this.userService.getUserData(this.userID).subscribe(user => {
                if (user) {
                    this.user = user;
                    this.firebaseFunctionService.getUserRole(this.userID).subscribe(role => {
                        if (role && role['role']) {
                            this.form.controls['userRole'].setValue(role['role']);
                        }
                    });
                }
            });

            this.dialectMatrixService.getRegionDataFromUser(this.userID).then(data => {
                this.regionData = data;
                console.log('Region data:', data);
            });

            this.captureEventService.getCaptureEventsForUser(this.userID).subscribe(events => {
                this.userEnrolledCaptureEvents = events;
            });
        }
    }

    ngOnInit(): void {
        this.form.controls['userRole'].valueChanges.subscribe(value => {
            if (value) {
                this.firebaseFunctionService.setUserRole(this.userID, value).subscribe(() => {
                    console.warn('Not yet implemented, we need a backend endpoint for this.');
                });
            }
        });
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (!this.searchOpen) {
            this.goBack();
        }
    }

    getUserBrithDate(): Date | string {
        if (this.user?.birthDate) {
            return this.user.birthDate.toDate();
        }
        return "";
    }

    setViewState(viewState: "userData" | "userEnrolledCaptureEvents" | "management" | "region") {
        this.currentView = viewState;
    }

    goBack() {
        this.location.back();
    }

    openSearch() {
        this.searchOpen = true;
    }

    enrollInCaptureEvent(captureEvent: CaptureEvent) {
        if (captureEvent.id) {
            this.captureEventService.enrollUserInCaptureEvent(captureEvent.id, this.userID).subscribe();
        }
    }

    openDeactivationModal() {
        this.deactivationModalVisible = true;
    }

    deactivateAccount() {
        this.firebaseFunctionService.disableUser(this.userID).subscribe(() => {
            this.userService.getUserData(this.userID).subscribe(user => {
                if (user) {
                    this.user = user;
                    this.form.controls['userRole'].setValue(user.accountState || "none");
                }
            });
        });
    }

    activateUser() {
        this.firebaseFunctionService.enableUser(this.userID).subscribe(() => {
            console.warn('Not yet implemented, we need a backend endpoint for this.');
            this.userService.getUserData(this.userID).subscribe(user => {
                if (user) {
                    this.user = user;
                }
            });
        });
    }

    openRegionMatcher() {
        this.regionMatcherVisible = true;
    }

    closeRegionalMatcher() {
        this.regionMatcherVisible = false;

        this.dialectMatrixService.getRegionDataFromUser(this.userID).then(data => {
            this.regionData = data;
        });

    }

    transformDialectVector() {
        if (this.regionData?.dialectMatrix?.dialect_vector) {
            return Object.entries(this.regionData.dialectMatrix.dialect_vector).map(([key, value]) => ({key, value}));
        } else {
            return [];
        }
    }

    transformDialectVectorCantonScores() {
        if (this.regionData?.dialectMatrix?.canton_scores) {
            return Object.entries(this.regionData.dialectMatrix.canton_scores).map(([key, value]) => ({key, value}));
        } else {
            return [];
        }
    }

    protected readonly Object = Object;
}
