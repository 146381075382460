import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {filter, Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ThemeService} from '../../services/theme.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-container',
    standalone: true,
    imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive, NgOptimizedImage],
    templateUrl: './container.component.html',
    styleUrl: './container.component.css',
    animations: []
})
export class ContainerComponent implements OnInit {
    activeLink = ''
    menuState: 'open' | 'closed' = 'closed';
    username = ""
    userImage: string = ""
    isProduction = false;

    theme = this.themeService.getThemeSignal();

    private routerSubscription: Subscription;

    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        private themeService: ThemeService
    ) {
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.activeLink = this.router.url;
        });

        environment.production ? this.isProduction = true : this.isProduction = false;
    }

    ngOnInit() {
        this.username = this.authService.getUserName();
        this.userImage = this.authService.getUserImage();

        // @ts-ignore
        document.getElementById("userImage").style.backgroundImage = `url(${this.userImage})`;
    }

    toggleMenu() {
        this.menuState = this.menuState === 'open' ? 'closed' : 'open';
    }

    isActive(router: string) {
        return this.activeLink.startsWith(router);
    }

    logoutUser() {
        this.authService.logOutUser()
    }

    protected readonly environment = environment;
}
