<main class="w-full p-8 flex flex-col border-t-4 border-orange-400 flex-grow relative bg-white dark:bg-gray-900 overflow-y-scroll">
	<app-search [(show)]="searchFieldOpen" (userSelect)="enrollUser($event)"></app-search>
	
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8 mx-auto max-w-7xl w-full">
		<div class="min-w-0 flex-1">
			<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">{{ captureEvent?.name }}</h2>
			<p class="mt-2 text-sm text-gray-700 dark:text-gray-300 mr-10">{{ captureEvent?.description }}</p>
		</div>
		<div class="mt-4 flex md:ml-4 md:mt-0">
			<button (click)="goBack()" type="button" class="inline-flex items-center rounded-md bg-gray-200 dark:bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-300 dark:hover:bg-white/20">
				Exit
				<span class="inline-flex items-center bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded px-1 ml-2">
                esc
            </span></button>
		</div>
	</header>
	
	<div class="mx-auto max-w-7xl w-full border-b border-gray-300 dark:border-gray-700 pb-5 sm:pb-0 bg-white dark:bg-gray-900">
		<div class="mt-3 sm:mt-4">
			<div class="sm:hidden">
				<label for="current-tab" class="sr-only">Select a tab</label>
				<select id="current-tab" name="current-tab" class="block w-full rounded-md border-gray-300 dark:border-gray-600 py-2 pl-3 pr-10 text-base text-gray-900 dark:text-white bg-gray-200 dark:bg-gray-800 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm">
					<option selected>Overview</option>
					<option>Users</option>
					<option>Recordings</option>
				</select>
			</div>
			
			<div class="hidden sm:block">
				<nav class="-mb-px flex space-x-8">
					<a (click)="setViewState('overview')"
					   [ngClass]="{'border-sky-500 text-sky-400': viewState === 'overview', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': viewState !== 'overview'}"
					   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Overview</a>
					
					<a (click)="setViewState('users')"
					   [ngClass]="{'border-sky-500 text-sky-400': viewState === 'users', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': viewState !== 'users'}"
					   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Users</a>
					
					<a (click)="setViewState('recordings')"
					   [ngClass]="{'border-sky-500 text-sky-400': viewState === 'recordings', 'border-transparent text-gray-900 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300': viewState !== 'recordings'}"
					   class="whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium">Recordings</a>
				</nav>
			</div>
		</div>
	</div>
	
	<div *ngIf="viewState === 'overview'" class="mx-auto max-w-7xl mt-4 flex flex-col w-full gap-4 overflow-y-scroll">
		
		<p class="text-xl font-medium leading-6 text-gray-900 dark:text-gray-400 mt-5">General Information</p>
		
		<div class="flex">
			<div class="mr-5 mt-2 group">
				<div class="flex justify-between mb-2">
					<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">App Thumbnail</p>
					<label for="file-upload" class="relative cursor-pointer text-sm font-medium leading-6 dark:text-gray-400 underline rounded-md bg-gray-50 dark:bg-gray-900 text-gray-900 hover:text-sky-500 focus:outline-none focus:ring-0">
						<span>Replace</span>
						<input id="file-upload" name="file-upload" type="file" class="sr-only" (change)="onFileSelected($event)">
					</label>
				</div>
				
				<img *ngIf="thumbnailUrl as thumbnailUrl" [src]="thumbnailUrl" alt="Thumbnail" class="max-h-[1000px]">
			</div>
			
			<div class="w-full">
				<div class="bg-white dark:bg-gray-900">
					<div class="mx-auto max-w-7xl">
						<div class="grid grid-cols-2 gap-px bg-gray-100 dark:bg-white/5">
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Number of enrolments</p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white">{{ users.length }}</span>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Average completion time</p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white">N/A</span>
									<span class="text-sm text-gray-900 dark:text-gray-400">mins</span>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Number of recordings</p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white">{{ dialectCaptures.length }}</span>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Ranking Link</p>
								<p class="mt-2 flex items-baseline gap-x-4">
									<a href="/ranking/{{captureEventID}}"><span class="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white underline">Open Ranking</span></a>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-90 col-span-2 row-span-2 flex flex-col  px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 pb-3">Registration QR Code</p>
								<qr-code [value]="getQRCodeJsonString" size="200" errorCorrectionLevel="H" lightColor="#101827" darkColor="#ffffffff" [margin]="0"></qr-code>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<p *ngIf="captureEvent?.isPublicEvent" class="text-xl font-medium leading-6 text-gray-900 dark:text-gray-400 mt-5">Event Information</p>
		
		<div *ngIf="captureEvent?.isPublicEvent" class="flex">
			<div class="mr-5 mt-2 group">
				<div class="flex justify-between mb-2">
					<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Web Thumbnail</p>
					<label for="file-upload-web" class="relative cursor-pointer text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 underline rounded-md bg-gray-50 dark:bg-gray-900 text-gray-900 hover:text-sky-500 focus:outline-none focus:ring-0">
						<span>Replace</span>
						<input id="file-upload-web" name="file-upload" type="file" class="sr-only" (change)="onFileSelected($event, true)">
					</label>
				</div>
				
				<img *ngIf="webThumbnailUrl as thumbnailUrl" [src]="thumbnailUrl" alt="Thumbnail" class="max-h-[1000px]">
			</div>
			
			<div class="w-full">
				<div class="bg-white dark:bg-gray-900">
					<div class="mx-auto max-w-7xl">
						<div class="grid grid-cols-1 gap-px bg-gray-100 dark:bg-white/5">
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Capture Event Description</p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-sm font-normal tracking-tight text-gray-900 dark:text-white" [innerHTML]="captureEvent?.eventDescription | newline"></span>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Capture Event Date</p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white">{{ captureEvent?.eventDate?.toDate() | date }}</span>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Capture Event Calendly Link</p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-sm font-semibold tracking-tight text-gray-900 dark:text-white"><a target="_blank" [href]="captureEvent?.calendlyLink" class="underline">{{ captureEvent?.calendlyLink }}</a></span>
								</p>
							</div>
							<div class="bg-white dark:bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
								<p class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400">Capture Event Location -
									<a class="underline" target="_blank" href="https://www.google.com/maps/place/?q=place_id:{{captureEvent?.placeId}}">google
										maps</a></p>
								<p class="mt-2 flex items-baseline gap-x-2">
									<span class="text-4xl font-semibold tracking-tight text-gray-900 dark:text-white">{{ captureEvent?.placeDescription }}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div *ngIf="viewState === 'users'" class="mx-auto max-w-7xl w-full bg-white dark:bg-gray-900 flex flex-grow">
		<div class="mx-auto max-w-7x flex flex-col flex-grow">
			<div class="bg-white dark:bg-gray-900 py-10 flex flex-col flex-grow">
				<div class="flex flex-col flex-grow">
					<div class="sm:flex sm:items-center">
						<div class="sm:flex-auto">
							<h1 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">Enrolled Users</h1>
							<p class="mt-2 text-sm text-gray-900 dark:text-gray-300">A list of all the users in your account including their name, title, email, and role.</p>
						</div>
						<div *ngIf="users.length !== 0" class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
							<button (click)="openSearch()" type="button" class="block rounded-md bg-sky-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500">
								enroll user
							</button>
						</div>
					</div>
					<div *ngIf="users.length !== 0" class="mt-8 flow-root">
						<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
									<thead>
									<tr>
										<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">Username</th>
										<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Email</th>
										<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Completion</th>
										<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
											<span class="sr-only">Edit</span>
										</th>
									</tr>
									</thead>
									<tbody class="divide-y divide-gray-200 dark:divide-gray-800">
									<tr *ngFor="let user of users">
										<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-0">{{ user?.userName ?? 'undefined' }}</td>
										<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-300">{{ user?.email ?? 'undefined' }}</td>
										<td class="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
											<div class="flex items-center justify-end gap-x-2 sm:justify-start">
												<time class="text-gray-900 dark:text-gray-400 sm:hidden" datetime="2023-01-23T11:00">45 minutes ago</time>
												<div class="flex-none rounded-full p-1 text-green-400 bg-green-400/10">
													<div class="h-1.5 w-1.5 rounded-full bg-current"></div>
												</div>
												<div class="hidden text-gray-900 dark:text-white sm:block">Completed</div>
											</div>
										</td>
										<td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
											<button class="text-orange-400" (click)="removeUser(user)">
												<svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
													<path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5

 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
												</svg>
											</button>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
					<div *ngIf="users.length === 0" class="text-center bg-white dark:bg-gray-900 flex flex-col flex-grow justify-center">
						<svg class="mx-auto h-12 w-12 text-gray-900 dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"/>
						</svg>
						<h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-300">No Enrollments</h3>
						<p class="mt-1 text-sm text-gray-900 dark:text-gray-400">There are no users enrolled in this Capture Session.</p>
						<div class="mt-6">
							<button (click)="openSearch()" type="button" class="inline-flex items-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700">
								<svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"/>
								</svg>
								enroll user
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div *ngIf="viewState === 'recordings'" class="mx-auto max-w-7xl w-full bg-white dark:bg-gray-900 overflow-y-scroll flex flex-grow relative">
		
		<div class="mx-auto w-full flex flex-col flex-grow relative">
			<div *ngIf="selectedCapture" class="absolute inset-0 z-10 w-full overflow-y-auto bg-white/50 h-full">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all w-[80%]">
						<div>
							<div class="justify-start sm:mt-5">
								<h3 class="text-base font-semibold leading-6 text-gray-900 my-3" id="modal-title">Associated Recordings</h3>
								<table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700 overflow-y-scroll overflow-hidden">
									<thead>
									<tr>
										<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">Capture ID</th>
										<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Microphone</th>
										<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
											<span class="sr-only">Edit</span>
										</th>
									</tr>
									</thead>
									<tbody class="divide-y divide-gray-200 dark:divide-gray-800">
									<tr *ngFor="let recording of selectedCapture.capturedVoice; let i = index">
										<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-0">{{ selectedCapture.id }}</td>
										<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-300">{{ selectedCaptureMetaData[i]?.label + " - " + selectedCaptureMetaData[i]?.microphoneName }}</td>
										<td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
											<p (click)="playAudioRecording(recording)" class="text-sky-400 hover:text-sky-300">Play Recording<span class="sr-only">, Lindsay Walton</span></p>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="mt-5 sm:mt-6">
							<button (click)="clearSelectedCapture()" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go back to dashboard</button>
						</div>
					</div>
				</div>
			</div>
			
			<div class="bg-white dark:bg-gray-900 py-10 flex flex-col flex-grow">
				<div class="flex flex-col flex-grow">
					<div class="sm:flex sm:items-center">
						<div class="sm:flex-auto">
							<h1 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">Captured Recordings</h1>
							<p class="mt-2 text-sm text-gray-900 dark:text-gray-300">A list of all Captures associated with the capture event.</p>
						</div>
						<div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" hidden>
							<button type="button" class="block rounded-md bg-sky-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500">
								enroll user
							</button>
						</div>
					</div>
					
					<div *ngIf="dialectCaptures.length !== 0" class="mt-8 flow-root">
						<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700 overflow-y-scroll overflow-hidden">
									<thead>
									<tr>
										<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">Capture ID</th>
										<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">Capture Date</th>
										<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Captured Text</th>
										<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">User ID</th>
										<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
											<span class="sr-only">Edit</span>
										</th>
									</tr>
									</thead>
									<tbody class="divide-y divide-gray-200 dark:divide-gray-800">
									<tr *ngFor="let capture of dialectCaptures">
										<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-0">{{ capture.id }}</td>
										<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-300 truncate max-w-[150px]">{{ convertFirebaseTimestampToDate(capture.date) | date }}</td>
										<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-300 truncate max-w-[300px]">{{ capture.capturedText }}</td>
										<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-300">{{ capture.userRef.id }}</td>
										<td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
											<p (click)="openPlayAudioModel(capture)" class="text-sky-400 hover:text-sky-300">Open Recording Player<span class="sr-only"></span></p>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					
					<div *ngIf="dialectCaptures.length === 0" class="text-center bg-white dark:bg-gray-900 flex flex-col flex-grow justify-center">
						<svg class="mx-auto h-12 w-12 text-gray-900 dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"/>
						</svg>
						
						<h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-300">No Recordings</h3>
						<p class="mt-1 text-sm text-gray-900 dark:text-gray-400">Let's motivate the minions and have them toil away for us. 💅<br>What are you waiting for?</p>
						<div class="mt-6">
							<button (click)="motivationalLore.openVideo()" type="button" class="inline-flex items-center rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700">
								<svg style="margin-bottom: -6px; margin-left: -6px; margin-right: 5px;" class="pr-[-20px] h-8 w-8" x="0px" y="0px" viewBox="0 0 24 30" fill="currentColor" aria-hidden="true">
									<g>
										<path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8   S16.4,20,12,20z"/>
										<polygon points="17,9 13,9 12,9 8,9 6,9 6,10 8,10 8,11 9,11 9,12 11,12 11,11 12,11 12,10 13,10 13,11 14,11 14,12 16,12 16,11    17,11 17,10 18,10 18,9  "/>
										<path d="M9,15.1c0,1,0.8,1.9,1.9,1.9h2.3c1,0,1.9-0.8,1.9-1.9V15H9V15.1z"/>
									</g>
								</svg>
								receive motivation
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<app-motivational-player #motivationalLore></app-motivational-player>
</main>
