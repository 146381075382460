<div class="flex min-h-full bg-gray-100 dark:bg-gray-900">
	<div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
		<div class="mx-auto w-full max-w-sm lg:w-96">
			<div>
				<ng-container *ngIf="isProduction">
					<img *ngIf="theme() === 'dark'" class="h-14 w-auto" src="/assets/ylabs_white_symbol.svg" alt="Ylabs LLC">
					<img *ngIf="theme() === 'light'" class="h-14 w-auto" src="/assets/ylabs_symbol.svg" alt="Ylabs LLC">
				</ng-container>
				<ng-container *ngIf="!isProduction">
					<img class="h-14 w-auto" src="/assets/ylabs-logo-dev.png" alt="Ylabs LLC - DEV">
				</ng-container>
				<h3 class="mt-6 text-sm font-medium text-gray-900 dark:text-white">Ylabs Data Admin {{isProduction ? 'PRD' : 'DEV'}}</h3>
				<h2 class="mt-2 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">Sign in to your account</h2>
			</div>
			
			<div class="mt-10">
				<div>
					<form [formGroup]="loginForm" (ngSubmit)="loginUser()" class="space-y-6">
						<div>
							<label for="email" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Email address</label>
							<div class="mt-2">
								<input id="email" name="email" type="email" autocomplete="email" required
								       class="block w-full rounded-md bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-sky-500 focus:ring-inset sm:text-sm sm:leading-6">
							</div>
						</div>
						
						<div>
							<label for="password" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Password</label>
							<div class="mt-2">
								<input id="password" name="password" type="password" autocomplete="current-password" required
								       class="block w-full rounded-md bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-sky-500 focus:ring-inset sm:text-sm sm:leading-6">
							</div>
						</div>
						
						<div>
							<button [disabled]="!loginForm.valid" type="submit"
							        class="flex w-full justify-center rounded-md bg-sky-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
								Sign in
							</button>
						</div>
					</form>
				</div>
				
				<div class="mt-10">
					<div class="relative">
						<div class="absolute inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-200 dark:border-white/10"></div>
						</div>
						<div class="relative flex justify-center text-sm font-medium leading-6">
							<span class="bg-gray-100 dark:bg-gray-900 px-6 text-gray-900 dark:text-white">Or continue with</span>
						</div>
					</div>
					
					<div class="mt-6 grid  gap-4">
						<a (click)="loginWithGoogle()"
						   class="flex w-full items-center justify-center gap-3 rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus-visible:ring-transparent">
							<svg class="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true">
								<path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335"/>
								<path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4"/>
								<path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05"/>
								<path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853"/>
							</svg>
							<span class="text-sm font-semibold leading-6">Google</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="relative hidden w-0 flex-1 lg:block">
		<div class="absolute bottom-0 right-0">
			<h1 class="text-sm dark:text-white font-normal mb-3 mr-4 z-20">Brought to you by <a href="https://ylabs.ch" target="_blank"><span class="underline">ylabs consulting</span></a></h1>
		</div>
		<img *ngIf="theme() === 'light'" class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1479292889369-1a48f234247e?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Background Image">
		<img *ngIf="theme() === 'dark'" class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1493575148873-af00581a1196?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Background Image">
		<div class="absolute top-0 bottom-0 left-0 right-0 bg-blue-950 bg-opacity-20"></div>
	</div>
</div>
