import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgForOf, NgIf} from '@angular/common';
import {User} from '../../interfaces/User.interface';
import {FormsModule} from '@angular/forms';
import {debounceTime, distinctUntilChanged, Subject, switchMap, takeUntil} from 'rxjs';
import {CaptureEvent} from '../../interfaces/CaptureEvent.interface';
import {UserService} from '../../services/user.service';
import {CaptureEventService} from '../../services/capture-event.service';

@Component({
    selector: 'app-search',
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        NgForOf
    ],
    templateUrl: './search.component.html',
    styleUrl: './search.component.css',
    animations: [
        trigger('backdrop', [
            state('void', style({opacity: 0})),
            state('*', style({opacity: 1})),
            transition(':enter', [animate('300ms ease-out')]),
            transition(':leave', [animate('200ms ease-in')])
        ]),
        trigger('modal', [
            state('void', style({opacity: 0, transform: 'scale(0.95)'})),
            state('*', style({opacity: 1, transform: 'scale(1)'})),
            transition(':enter', [animate('300ms ease-out')]),
            transition(':leave', [animate('200ms ease-in')])
        ])
    ]
})
export class SearchComponent implements OnDestroy, OnInit {
    searchValue = '';
    users: User[] = [];
    captureEvents: CaptureEvent[] = [];
    private destroy$ = new Subject<void>();
    public input$ = new Subject<string>();

    @Output() userSelect = new EventEmitter<User>();
    @Output() captureEventSelect = new EventEmitter<CaptureEvent>();
    @Input() show = true;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() searchType: "users" | "captureEvents" = "users";

    constructor(private userService: UserService, private captureEventService: CaptureEventService) {
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.closeSearch()
    }

    ngOnInit() {
        if (this.searchType === "users") {
            this.input$.pipe(
                debounceTime(300), // Debounce the input to reduce the load
                distinctUntilChanged(), // Avoid processing the same input repeatedly
                switchMap(value => value ? this.userService.queryUsers(value) : []),
                takeUntil(this.destroy$) // Cleanup subscription on component destroy
            ).subscribe(users => {
                this.users = users;
            });
        } else {
            this.input$.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(value => value ? this.captureEventService.queryCaptureEvents(value) : []),
                takeUntil(this.destroy$)
            ).subscribe(captureEvents => {
                console.log(captureEvents);
                this.captureEvents = captureEvents;
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    closeSearch() {
        this.show = false;
        this.showChange.emit(this.show);
    }

    selectUser(user: User) {
        this.userSelect.emit(user);
        this.closeSearch();
    }

    selectCaptureEvent(captureEvent: CaptureEvent) {
        this.captureEventSelect.emit(captureEvent);
        this.closeSearch();
    }

    checkIfGotData() {
        if (this.searchType === "users") {
            return this.users.length > 0;
        } else {
            return this.captureEvents.length > 0;
        }
    }
}
