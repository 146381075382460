import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {DeleteTextSnippetModal} from '../base-mode-delete-modal/delete-text-snippet-modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Location, NgForOf, NgIf} from '@angular/common';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {BaseTextBundle, RegionalTextBundle} from '../../interfaces/TextBundle.interface';
import {TextSnippet} from '../../interfaces/TextSnippet.interface';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TextBundlesService} from '../../services/text-bundles.service';
import {TextSnippetService} from '../../services/text-snippet.service';
import {first, tap} from 'rxjs';

@Component({
    selector: 'app-regional-entity-editor',
    standalone: true,
    imports: [
        DeleteTextSnippetModal,
        FormsModule,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        RouterLink
    ],
    templateUrl: './regional-entity-editor.component.html',
    styleUrl: './regional-entity-editor.component.css'
})
export class RegionalEntityEditorComponent implements OnInit {
    dropDownMenuVisible: boolean = false;
    textFieldEdited: boolean = false;
    deleteModelOpen = false
    textSnippetEditLocked: boolean = false;

    textSnippetReferenceIndex: number = 0;
    textSnippetReferences: DocumentReference[] = [];
    textBaseBundle!: BaseTextBundle;
    textSnippet!: TextSnippet;
    regionalTextBundles: RegionalTextBundle[] = [];
    currentRegionalTextBundle!: RegionalTextBundle;

    textSnippetOriginValue: string = "";
    localizedTextSnippetValue: string = "";
    originalLocalizedTextSnippetValue: string = "";


    regionalTextBundleID: string = "";
    baseTextBundleID: string = "";
    textSnippetID: string = "";

    private metaPressed: boolean = false;

    @ViewChild('menu') menuElement!: ElementRef;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey(event: KeyboardEvent): void {
        this.location.back();
    }

    @HostListener('document:keydown.meta.ArrowLeft', ['$event'])
    handleLeftArrowKey(event: KeyboardEvent): void {
        event.preventDefault();

        if (this.textSnippetReferenceIndex > 0) {
            this.textSnippetReferenceIndex--;
            this.loadTextSnippet();
        }
    }

    @HostListener('document:keydown.meta.ArrowRight', ['$event'])
    handleRightArrowKey(event: KeyboardEvent): void {
        event.preventDefault();

        if (this.textSnippetReferenceIndex < this.textSnippetReferences.length - 1) {
            this.textSnippetReferenceIndex++;
            this.loadTextSnippet();
        }
    }

    @HostListener('keydown.meta.enter', ['$event'])
    handleCommandEnterKey(event: KeyboardEvent): void {
        this.updateLocalisationToTextSnippet();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private textBundleService: TextBundlesService,
        private textSnippetService: TextSnippetService
    ) {
    }

    ngOnInit(): void {
        this.loadRouteParams();
        this.loadData();
    }

    toggleDropDownMenu(): void {
        this.dropDownMenuVisible = !this.dropDownMenuVisible;
    }

    showDeleteModal() {
        this.deleteModelOpen = true;
    }

    navigateToRegion(region: any): void {
        const queryParams = {
            regionalTextBundleID: region.id,
            baseTextBundleID: this.baseTextBundleID,
            textSnippetID: this.textSnippet.id,
        };

        const fullUrl = this.router.url;
        const segments = fullUrl.split('/')
        const rootPath = `${segments[1]}/${segments[2]}`;

        this.router.navigate([rootPath, 'entity-editor'], {queryParams}).then(() => {
            this.loadRouteParams();
            this.loadData();
            this.toggleDropDownMenu();
        });
    }

    navigateToBase() {
        this.router.navigate(["app", 'base-text-bundles', 'entity-editor'], {
            queryParams: {
                baseTextBundleID: this.baseTextBundleID,
                textSnippetID: this.textSnippetID
            }
        });
    }

    discardTranslationChanges(): void {
        this.localizedTextSnippetValue = this.originalLocalizedTextSnippetValue;
        this.textFieldEdited = false;
    }

    deleteTextSnippet() {
        this.textSnippetService.deleteTextSnippet(this.textSnippet.id, this.baseTextBundleID).subscribe(() => {
            // console.log('TextSnippet deleted');
        });
    }

    duplicateTextSnippet() {
        this.textSnippetService.duplicateTextSnippet(this.textSnippet.id, this.baseTextBundleID).subscribe((doucmentID) => {
            // console.log(`TextSnippet duplicated with ID: ${doucmentID}`);
        });
    }

    updateLocalisationToTextSnippet() {
        if (!this.textSnippetEditLocked) {
            this.textSnippetService.addLocalisationToTextSnippet(this.textSnippet.id, this.currentRegionalTextBundle.id, this.textBaseBundle.id, this.localizedTextSnippetValue).subscribe(() => {
                this.textSnippetReferenceIndex++;
                this.loadTextSnippet();
                this.textFieldEdited = false;
            });
        }
    }

    onTextInput() {
        this.textFieldEdited = this.localizedTextSnippetValue !== this.originalLocalizedTextSnippetValue;
    }

    private loadData() {
        this.loadBaseTextBundle();
        this.loadTextSnippetsReferences();
        this.loadRegionalTextBundle();
    }

    private setRegionalTranslationValue() {
        let keys: string[]

        if (this.textSnippet.localized_content) {
            keys = Object.keys(this.textSnippet.localized_content);
        } else {
            keys = [];
        }
        // @ts-ignore
        const localizedValue = keys.includes(this.regionalTextBundleID) ? this.textSnippet.localized_content[this.regionalTextBundleID] as string : "";
        this.localizedTextSnippetValue = localizedValue;
        this.originalLocalizedTextSnippetValue = localizedValue;
    }

    private loadRouteParams(): void {
        this.route.queryParams.subscribe(params => {
            this.regionalTextBundleID = params['regionalTextBundleID'];
            this.baseTextBundleID = params['baseTextBundleID'];
            this.textSnippetID = params['textSnippetID'];
        });
    }

    private loadBaseTextBundle(): void {
        this.textBundleService.getBaseTextBundleData(this.baseTextBundleID)
            .pipe(
                tap((baseTextBundle) => {
                    this.textBaseBundle = baseTextBundle;
                }),
                first()
            )
            .subscribe({
                next: (baseTextBundle) => {
                },
                complete: () => {
                    this.loadTextSnippet(true);
                }
            });
    }

    private loadRegionalTextBundle(): void {
        this.textBundleService.getAllRegionalTextBundlesData(this.baseTextBundleID).subscribe((regionalTextBundles) => {
            this.regionalTextBundles = regionalTextBundles;
            this.currentRegionalTextBundle = this.regionalTextBundles.find(value => value.id === this.regionalTextBundleID) || this.regionalTextBundles[0];
        });
    }

    private loadTextSnippetsReferences(): void {
        this.textSnippetService.getTextSnippetsReferences(this.baseTextBundleID).subscribe((textSnippetReferences) => {
            this.textSnippetReferences = textSnippetReferences;
        });
    }

    private loadTextSnippet(initial: boolean = false): void {
        this.textFieldEdited = false;
        if (initial && this.textSnippetID) {
            this.textSnippetReferenceIndex = this.findIndexOfTextSnippetBasedOnID(this.textSnippetID);
        }

        this.textSnippetService.getTextSnippetByDocRef(this.textSnippetReferences[this.textSnippetReferenceIndex]).subscribe((textSnippet) => {
            this.textSnippet = textSnippet;
            this.textSnippetOriginValue = textSnippet.text;

            this.setRegionalTranslationValue()
        });
    }

    private findIndexOfTextSnippetBasedOnID(textSnippetId: string): number {
        return this.textSnippetReferences.findIndex(value => value.id === textSnippetId);
    }

    protected readonly Object = Object;
}
