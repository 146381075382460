import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Auth, GoogleAuthProvider} from '@angular/fire/auth';
import {AuthService} from '../../services/auth.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {AlertService} from '../../services/alert.service';
import {ThemeService} from '../../services/theme.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AsyncPipe,
        NgIf
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
    private auth = inject(Auth);
    theme = this.themeService.getThemeSignal();
    provider = new GoogleAuthProvider();
    loginForm!: FormGroup;
    isProduction = false;

    constructor(private formBuilder: FormBuilder, private authService: AuthService, private alertService: AlertService, private themeService: ThemeService) {
        this.isProduction = environment.production;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        })
    }

    loginUser() {
        if (this.loginForm.invalid) {
            this.authService.logInUsingCredentials(this.loginForm.value.email, this.loginForm.value.password)
        }
    }

    async loginWithGoogle() {
        await this.authService.logInUsingGoogle().then((loginSuccess) => {
                if (loginSuccess) {
                    this.authService.redirectToApp();
                } else {
                    this.alertService.showAlert('Unauthorized', 'Only admins or superadmins can access the admin panel')
                }
            }
        )
    }
}
