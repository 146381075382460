import {Component, HostListener, OnInit} from '@angular/core';
import {AsyncPipe, DatePipe, Location, NgClass, NgForOf, NgIf} from '@angular/common';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {QrCodeModule} from 'ng-qrcode';
import {CaptureEvent} from '../../interfaces/CaptureEvent.interface';
import {user} from '@angular/fire/auth';
import {User} from '../../interfaces/User.interface';
import {SearchComponent} from '../search/search.component';
import {CaptureEventService} from '../../services/capture-event.service';
import {UserService} from '../../services/user.service';
import {DialectCaptureInterface} from '../../interfaces/DialectCapture.interface';
import {AudioRecordingMetaData, CaptureAudioService} from '../../services/capture-audio.service';
import {MotivationalPlayerComponent} from '../motivational-player/motivational-player.component';
import {NewlinePipe} from '../../pipes/newline.pipe';

@Component({
    selector: 'app-capture-event-view',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        RouterLink,
        QrCodeModule,
        NgClass,
        SearchComponent,
        MotivationalPlayerComponent,
        AsyncPipe,
        DatePipe,
        NewlinePipe
    ],
    templateUrl: './capture-event-view.component.html',
    styleUrl: './capture-event-view.component.css'
})
export class CaptureEventViewComponent implements OnInit {
    viewState: "overview" | "users" | "recordings" | "settings" = "recordings";
    captureEventID: string = "";
    captureEvent: CaptureEvent | undefined;
    users: User[] = [];
    dialectCaptures: DialectCaptureInterface[] = [];
    searchFieldOpen: boolean = false;
    audio = new Audio();
    thumbnailUrl!: string;
    webThumbnailUrl!: string;
    playAudioModelOpen = false;
    selectedCapture?: DialectCaptureInterface;
    selectedCaptureMetaData: [AudioRecordingMetaData?] = [];

    constructor(
        private route: ActivatedRoute,
        private captureEventService: CaptureEventService,
        private location: Location,
        private userService: UserService,
        private captureAudioService: CaptureAudioService,
        private captureService: CaptureEventService
    ) {
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (!this.searchFieldOpen) {
            this.goBack()
        }
    }

    ngOnInit(): void {
        this.captureEventID = this.route.snapshot.paramMap.get('captureEventID') as string;
        this.getCaptureEvent();
        this.getUsers();

        this.captureEventService.getCapturesByEvent(this.captureEventID).subscribe(captures => {
            this.dialectCaptures = captures
            // @ts-ignore
            window.dialectCaptures = captures;
        });
    }

    convertFirebaseTimestampToDate(timestamp: any) {
        return new Date(timestamp.seconds * 1000)
    }

    getCaptureEvent() {
        this.captureEventService.getCaptureEvent(this.captureEventID).subscribe((event) => {
            this.captureEvent = event;
            // @ts-ignore
            window.captureEvent = event;
            this.thumbnailUrl = this.captureEvent?.thumbnail
            this.webThumbnailUrl = this.captureEvent?.webThumbnail
        });
    }

    getUsers() {
        this.captureEventService.getEnrolledUsers(this.captureEventID).subscribe((users) => {
            console.log(users);
            this.users = [];
            for (let user of users) {
                this.userService.getUserData(user).subscribe((userData) => {
                    this.users.push(userData as User);
                });
            }
        });
    }

    playAudioRecording(recordingPath: string): void {
        console.log(recordingPath)
        this.captureAudioService.getAudioRecording(recordingPath).subscribe(url => {
            this.audio.src = url;
            this.audio.load();
            this.audio.play();
        });
    }

    goBack() {
        this.location.back();
    }


    removeUser(user: User) {
        this.captureEventService.removeUserFromCaptureEvent(this.captureEventID, user.id as string)
    }

    setViewState(viewState: "overview" | "users" | "recordings" | "settings") {
        this.viewState = viewState;
    }


    protected readonly user = user;

    openSearch() {
        this.searchFieldOpen = true;
    }

    enrollUser($event: User) {
        this.captureEventService.enrollUserInCaptureEvent(this.captureEventID, $event.id as string)
    }


    get getQRCodeJsonString() {
        return JSON.stringify({
            captureEventId: this.captureEventID
        })
    }

    onFileSelected($event: Event, web = false) {
        const file = ($event.target as HTMLInputElement).files?.[0];
        if (file) {
            const _file = file;
            const imageSrc = URL.createObjectURL(file);

            if (web) {
                this.captureService.updateWebThumbnailOfCaptureEvent(this.captureEventID, _file).then(() => {
                    this.webThumbnailUrl = imageSrc;
                });
                return;
            } else {
                this.captureService.updateThumbnailOfCaptureEvent(this.captureEventID, _file).then(() => {
                    this.thumbnailUrl = imageSrc;
                });
            }
        }
    }

    openPlayAudioModel(capture: DialectCaptureInterface) {
        this.playAudioModelOpen = true;
        this.selectedCapture = capture;
        this.selectedCaptureMetaData = [];

        this.selectedCapture.capturedVoice.forEach((recordingPath) => {
            console.log(recordingPath)
            this.captureAudioService.getAudioRecordingMetaData(recordingPath).subscribe((metaData) => {
                if (metaData){
                    this.selectedCaptureMetaData.push(metaData);
                }
            });
        });
    }

    clearSelectedCapture() {
        this.playAudioModelOpen = false;
        this.selectedCapture = undefined;
        this.selectedCaptureMetaData = [];
    }
}
