<main class="w-full p-8 border-t-4 border-blue-400 bg-white dark:bg-gray-900">
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8 w-full mx-auto max-w-7xl">
		<div class="min-w-0 flex-1">
			<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">Base Text Bundles</h2>
		</div>
		<div class="mt-4 flex md:ml-4 md:mt-0">
		</div>
	</header>
	
	<div>
		<div class="mx-auto max-w-7xl">
			<div>
				<div class="sm:flex sm:items-center">
					<div class="sm:flex-auto">
						<p class="text-sm text-gray-700 dark:text-gray-300">
							Base Text Bundles serve as the foundational collections of dialect snippets, standardized in English. They provide the core content from which regional text bundles are derived, ensuring consistency and
							facilitating the localization process across different languages and dialects.
						</p>
					</div>
					<div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
				</div>
				<div class="mt-8 flow-root">
					<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table class="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
								<thead>
								<tr>
									<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0">Name</th>
									<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">ID</th>
									<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Description</th>
									<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">Categories</th>
									<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
										<span class="sr-only">Edit</span>
									</th>
								</tr>
								</thead>
								<tbody class="divide-y divide-gray-200 dark:divide-gray-800">
								<tr *ngFor="let textBundle of baseTextBundles">
									<td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-0">{{ textBundle.name }}</td>
									<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 dark:text-gray-300">{{ textBundle.id }}</td>
									<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 dark:text-gray-300 truncate max-w-[190px]">{{ textBundle.description }}</td>
									<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 dark:text-gray-300 truncate max-w-[190px]">{{ textBundle.categories }}</td>
									<td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
										<a [routerLink]="'/app/base-text-bundles/' + textBundle.id" class="text-blue-600 dark:text-sky-400 hover:text-blue-500 dark:hover:text-sky-300">Open<span class="sr-only">, Lindsay Walton</span></a>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
