import {Routes} from '@angular/router';
import {UsersComponent} from './pages/users/users.component';
import {ContainerComponent} from './shell/container/container.component';
import {BaseTextBundlesComponent} from './pages/base-text-bundles/base-text-bundles.component';
import {RegionalTextBundlesComponent} from './pages/regional-text-bundles/regional-text-bundles.component';
import {TextBundleViewComponent} from './components/text-bundle-view/text-bundle-view.component';
import {CaptureEventsComponent} from './pages/capture-events/capture-events.component';
import {CaptureEventRegisterComponent} from './components/capture-event-register/capture-event-register.component';
import {CaptureEventViewComponent} from './components/capture-event-view/capture-event-view.component';
import {UserViewComponent} from './components/user-view/user-view.component';
import {LoginComponent} from './pages/login/login.component';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {BaseEntityEditorComponent} from './components/base-entity-editor/base-entity-editor.component';
import {RegionalEntityEditorComponent} from './components/regional-entity-editor/regional-entity-editor.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {RegionalCaptureDataComponent} from './pages/regional-capture-data/regional-capture-data.component';
import {CaptureEventRankingComponent} from './pages/capture-event-ranking/capture-event-ranking.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {authGuardPipe: redirectUnauthorizedToLogin},
    },
    {
        path: "ranking/:captureEventID",
        component: CaptureEventRankingComponent
    },
    {
        path: 'app',
        component: ContainerComponent,
        children: [
            {path: "base-text-bundles/entity-editor", component: BaseEntityEditorComponent},
            {path: "regional-text-bundles/entity-editor", component: RegionalEntityEditorComponent},
            {path: 'base-text-bundles', component: BaseTextBundlesComponent},
            {path: 'base-text-bundles/:baseTextBundleID', component: TextBundleViewComponent},
            {path: "regional-text-bundles", component: RegionalTextBundlesComponent},
            {path: "regional-text-bundles/:baseTextBundleID/:regionalTextBundleID", component: TextBundleViewComponent},
            {path: "regional-capture-data", component: RegionalCaptureDataComponent},
            {path: 'users/:userID', component: UserViewComponent},
            {path: 'users', component: UsersComponent},
            {path: "capture-events", component: CaptureEventsComponent},
            {path: "capture-events/register", component: CaptureEventRegisterComponent},
            {path: "capture-events/:captureEventID", component: CaptureEventViewComponent},
            {path: "settings", component: SettingsComponent},
            {path: "**", redirectTo: "base-text-bundles"}
        ],
        canActivate: [AngularFireAuthGuard],
        data: {authGuardPipe: redirectUnauthorizedToLogin},
    },
];
