<div *ngIf="textSnippet" class="w-full p-5 border-t-4 relative border-green-400 bg-white dark:bg-gray-900">
	<app-delete-text-snippet-modal (deletionEventEmitter)="deleteTextSnippet()" [(showModal)]="deleteModelOpen" [textSnippetID]="textSnippet.id"></app-delete-text-snippet-modal>
	<div class="max-w-7xl w-full mx-auto">
		<div class="md:flex md:items-center md:justify-between mt-5 mb-8">
			<div class="min-w-0 flex-1">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">Localization Data Entity Editor</h2>
			</div>
			<div class="mt-4 flex md:ml-4 md:mt-0">
				<button class="inline-flex items-center rounded-md border-1 border border-gray-300 dark:border-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-200 dark:hover:bg-white/20" type="button">
					Exit
					<span class="inline-flex items-center bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded px-1 ml-2">
            esc
        </span>
				</button>
			</div>
		</div>
		
		<div *ngIf="textSnippet" class="relative bg-white dark:bg-gray-900 border-gray-300 dark:border-gray-700 pb-5 sm:flex sm:items-center sm:justify-between mb-5">
			<div>
				<h3 class="text-base font-medium leading-6 text-gray-900 dark:text-white"><span class="font-thin">You are editing</span> Localization Data</h3>
				<h3 class="text-base font-medium leading-6 text-gray-900 dark:text-white"><span class="font-thin">From base text bundle </span> {{ textBaseBundle.name }}</h3>
			</div>
			<div class="flex">
        <span *ngIf="currentRegionalTextBundle" class="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-400 dark:text-green-400 ring-1 ring-inset ring-green-500/20 mr-3">{{ currentRegionalTextBundle.country }}
	        - {{ currentRegionalTextBundle.region_name }}</span>
				<div class="relative inline-block text-left">
					<div>
						<button (click)="toggleDropDownMenu()" aria-expanded="true" aria-haspopup="true" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-200 dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-300 dark:hover:bg-gray-700" type="button">
							Text Bundle Regions
							<svg aria-hidden="true" class="-mr-1 h-5 w-5 text-gray-500 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20">
								<path clip-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" fill-rule="evenodd"/>
							</svg>
						</button>
					</div>
					
					<div #menu [style.display]="dropDownMenuVisible ? 'block' : 'none'"
					     aria-labelledby="menu-button" aria-orientation="vertical" class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-gray-200 dark:bg-gray-800 shadow-lg ring-1 ring-black dark:ring-black ring-opacity-5 focus:outline-none" role="menu" tabindex="-1">
						<div class="py-1" role="none">
							<div class="px-4 pt-2 pb-3 bg-gray-200 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-500" role="none">
								<p (click)=" navigateToBase()" class="truncate text-sm text-gray-900 dark:text-gray-400" role="none">Base Text Bundle</p>
							</div>
							<a (click)="navigateToRegion(region)" *ngFor="let region of regionalTextBundles" [routerLink]="'/app/entity-editor'" class="text-gray-900 dark:text-gray-400 block px-4 py-2 text-sm hover:bg-gray-300 dark:hover:bg-gray-700">{{ region.country }}
								- {{ region.region_name }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<form class="shadow-sm ring-1 ring-gray-300 dark:ring-gray-700 sm:rounded-xl md:col-span-3 w-full">
			<div class="px-4 py-6 sm:p-8">
				<div class="flex gap-4 w-full">
					<div class="flex gap-4 w-full">
                <span class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-800">
                    <svg aria-hidden="true" class="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6">
                        <circle cx="3" cy="3" r="3"/>
                    </svg>
	                {{ this.textSnippetReferenceIndex + 1 }} of {{ this.textSnippetReferences.length }}
                </span>
						<span class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-800">
                    <svg aria-hidden="true" class="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6">
                        <circle cx="3" cy="3" r="3"/>
                    </svg>
                    ID: {{ textSnippet.id }}
                </span>
						<span class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-800">
                    <svg aria-hidden="true" class="h-1.5 w-1.5 fill-blue-400" viewBox="0 0 6 6">
                        <circle cx="3" cy="3" r="3"/>
                    </svg>
                    Localisations sets: {{ this.textSnippet.localized_content ? Object.keys(this.textSnippet.localized_content).length : 0 }}
                </span>
						<span class="isolate inline-flex rounded-md shadow-sm">
                    <button (click)="showDeleteModal()" class="relative inline-flex items-center rounded-l-md px-3 py-2 text-xs font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300/20 dark:ring-gray-400/20 hover:bg-red-800 dark:hover:bg-red-800 focus:z-10 focus:ring-2 focus:ring-red-700" type="button">Delete</button>
                    <button (click)="duplicateTextSnippet()" class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-xs font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300/20 dark:ring-gray-400/20 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:ring-2 focus:ring-indigo-500" type="button">Duplicate</button>
                </span>
					</div>
					<span *ngIf="textSnippetEditLocked" title="You cannot edit this item because it already has localisation reference content" class="flex justify-center inline-flex w-[100px] cursor-help items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">Edit Locked</span>
				</div>
				<div class="border-b border-gray-300 dark:border-gray-700 pb-5 bg-white dark:bg-gray-900 mt-8">
					<h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">Origin Text</h3>
					<p class="mt-2 max-w-4xl text-sm text-gray-700 dark:text-gray-300">{{ textSnippetOriginValue }}</p>
				</div>
				<div class="grid max-w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-8">
					<div class="col-span-full">
						<label class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Localized Snippet Value</label>
						<div class="mt-2">
                    <textarea [disabled]="textSnippetEditLocked" (input)="onTextInput()" [(ngModel)]="localizedTextSnippetValue" class="block w-full rounded-md mb-5 border-0 bg-gray-100 dark:bg-white/5 h-auto py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm

:text-sm sm:leading-6 resize-none" id="text-preview" name="text-field" placeholder="Enter your text here (max 20 words)"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-between gap-x-6 border-t border-gray-300 dark:border-gray-600 px-4 py-4 sm:px-8">
				<div>
					<button class="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-200 dark:hover:bg-white/20" type="button">
            <span class="inline-flex items-center bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded px-1 ">
                <svg class="size-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
					</button>
					<button class="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm hover:bg-gray-200 dark:hover:bg-white/20 ml-2" type="button">
            <span class="inline-flex items-center bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white rounded px-1 ">
                <svg class="size-6" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
					</button>
				</div>
				<div class="flex gap-x-6">
					<button (click)="discardTranslationChanges()" [disabled]="!textFieldEdited" class="text-sm font-semibold leading-6 text-gray-900 dark:text-white disabled:text-gray-400 dark:disabled:text-gray-400" type="button">
						Discard
					</button>
					<button (click)="updateLocalisationToTextSnippet()" [disabled]="!textFieldEdited" class="rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500 disabled:text-sky-200 disabled:bg-gray-200/10" type="submit">
						{{ textFieldEdited ? 'Update' : 'No Changes' }}
						<span *ngIf="textFieldEdited" class="ml-3 flex-none text-xs font-semibold"><kbd class="font-sans">⌘</kbd><kbd class="font-sans">enter</kbd></span>
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
