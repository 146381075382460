<div class="flex flex-row h-full max-w-full">
	<div class="flex flex-col h-full min-w-[320px]">
		<div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100 dark:bg-black/10 px-6 ring-1 ring-gray-300 dark:ring-white/5">
			<div class="flex h-16 shrink-0 items-center">
				<ng-container *ngIf="isProduction">
					<img *ngIf="theme() === 'dark'" class="h-9 w-auto" src="/assets/ylabs_white_symbol.svg" alt="Ylabs LLC">
					<img *ngIf="theme() === 'light'" class="h-9 w-auto" src="/assets/ylabs_symbol.svg" alt="Ylabs LLC">
				</ng-container>
				<ng-container *ngIf="!isProduction">
					<img class="h-9 w-auto" src="/assets/ylabs-logo-dev.png" alt="Ylabs LLC - DEV">
				</ng-container>
				
				<h3 class="ml-4 text-lg font-semibold text-gray-900 dark:text-white">Ylabs Data Admin {{environment.production ? 'PRD' : 'DEV'}}</h3>
			</div>
			<nav class="flex flex-1 flex-col">
				<ul role="list" class="flex flex-1 flex-col gap-y-7">
					<li>
						<ul role="list" class="-mx-2 space-y-1">
							<li>
								<a routerLink="/app/base-text-bundles"
								   [class]="isActive('/app/base-text-bundles') ? 'bg-gray-200 dark:bg-gray-800 text-sky-600 dark:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'">
									<svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M21.75 17.25v-.228a4.5 4.5 0 00-.12-1.03l-2.268-9.64a3.375 3.375 0 00-3.285-2.602H7.923a3.375 3.375 0 00-3.285 2.602l-2.268 9.64a4.5 4.5 0 00-.12 1.03v.228m19.5 0a3 3 0 01-3 3H5.25a3 3 0 01-3-3m19.5 0a3 3 0 00-3-3H5.25a3 3 0 00-3 3m16.5 0h.008v.008h-.008v-.008zm-3 0h.008v.008h-.008v-.008z"/>
									</svg>
									Base Text Bundles
								</a>
							</li>
							<li hidden>
								<a routerLink="/app/regional-text-bundles/"
								   [class]="isActive('/app/regional-text-bundles')  ? 'bg-gray-200 dark:bg-gray-800 text-sky-600 dark:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'">
									<svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"/>
									</svg>
									Regional Text Bundles
								</a>
							</li>
							<li>
								<a routerLink="/app/regional-capture-data"
								   [class]="isActive('/app/regional-capture-data')  ? 'bg-gray-200 dark:bg-gray-800 text-sky-600 dark:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'">
									<svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m-6 3.75 3 3m0 0 3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75"/>
									</svg>
									Regional Capture Data
								</a>
							</li>
							<li>
								<a routerLink="/app/capture-events"
								   [class]="isActive('/app/capture-events')  ? 'bg-gray-200 dark:bg-gray-800 text-sky-600 dark:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 shrink-0">
										<path stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"/>
									</svg>
									Capture Events
								</a>
							</li>
							<li>
								<a routerLink="/app/users"
								   [class]="isActive('/app/users')  ? 'bg-gray-200 dark:bg-gray-800 text-sky-600 dark:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'">
									<svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
										<path stroke-linecap="round" stroke-linejoin="round" d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
									</svg>
									Users
								</a>
							</li>
							<li>
								<a routerLink="/app/settings"
								   [class]="isActive('/app/settings') ? 'bg-gray-200 dark:bg-gray-800 text-sky-600 dark:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold' : 'text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 shrink-0">
										<path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"/>
										<path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
									</svg>
									Settings
								</a>
							</li>
						</ul>
					</li>
					<li class="-mx-6 mt-auto">
						<a class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-800">
							<div class="flex w-full content-center gap-3 items-center">
								<div id="userImage" class="h-8 w-8 rounded-full bg-cover bg-no-repeat"></div>
								<span class="sr-only">Your profile</span>
								<span aria-hidden="true">{{ username }}</span>
							</div>
							<div class="flex content-center gap-3 items-center">
								<a (click)="logoutUser()" class="text-gray-700 dark:text-gray-400 hover:text-sky-600 dark:hover:text-sky-300">Logout</a>
							</div>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	</div>
	
	<main class="flex flex-1 overscroll-y-auto">
		<router-outlet></router-outlet>
	</main>
</div>
