import {Component, EventEmitter, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [
        NgIf
    ],
    templateUrl: './deactivation-modal.component.html',
    styleUrl: './deactivation-modal.component.css',
    animations: [
        trigger('backdrop', [
            state('void', style({opacity: 0})),
            state('*', style({opacity: 1})),
            transition(':enter', [animate('300ms ease-out')]),
            transition(':leave', [animate('200ms ease-in')])
        ]),
        trigger('modal', [
            state('void', style({opacity: 0, transform: 'translateY(4px) scale(0.95)'})),
            state('*', style({opacity: 1, transform: 'translateY(0) scale(1)'})),
            transition(':enter', [animate('300ms ease-out')]),
            transition(':leave', [animate('200ms ease-in', style({transform: 'translateY(4px) scale(0.95)', opacity: 0}))])
        ])
    ]
})
export class DeactivationModalComponent {
    @Input() showModal: boolean = false;
    @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() deactivateAccountEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    hideDeactivationModal() {
        this.showModal = false;
        this.showModalChange.emit(this.showModal);
    }

    deactivateAccount() {
        this.deactivateAccountEvent.emit(true);
        this.hideDeactivationModal();
    }
}
