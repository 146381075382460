import {inject, Injectable} from '@angular/core';
import {catchError, from, map, Observable, tap, throwError} from 'rxjs';
import {collection, collectionData, doc, Firestore, getDoc, runTransaction} from '@angular/fire/firestore';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {TextSnippet} from '../interfaces/TextSnippet.interface';
import {TextBundlesService} from './text-bundles.service';

@Injectable({
    providedIn: 'root'
})
export class TextSnippetService {
    private firestore: Firestore = inject(Firestore);
    public textSnippetPath = 'TextSnippets';

    constructor(private textBundleService: TextBundlesService) {
    }

    getRef(regionalTextBundleRef: DocumentReference, textSnippetId: string) {
        return doc(this.firestore, `${regionalTextBundleRef.path}/${this.textSnippetPath}/${textSnippetId}`);
    }

    getTextSnippetsReferences(textBundleId: string): Observable<DocumentReference[]> {
        const path = `${this.textBundleService.textBundlePath}/${textBundleId}/${this.textSnippetPath}`;
        const snippetsCollection = collection(this.firestore, path);
        return collectionData(snippetsCollection, {idField: 'doc_id'})
            .pipe(
                map(snippets => snippets.map(snippet =>
                    doc(this.firestore, `${path}/${snippet.doc_id}`) as unknown as DocumentReference
                )),
            );
    }

    getTextSnippetByDocRef(documentRef: DocumentReference): Observable<TextSnippet> {
        return from(getDoc(documentRef)).pipe(
            map(docSnapshot => {
                if (docSnapshot.exists()) {
                    return docSnapshot.data() as TextSnippet;
                } else {
                    throw new Error('Document not found');
                }
            }),
            catchError(err => throwError(() => new Error(`Error retrieving document: ${err.message}`)))
        );
    }

    addLocalisationToTextSnippet(textSnippetId: string, regionalTextBundleId: string, baseTextBundleId: string, localizedContent: string): Observable<void> {
        const path = `${this.textBundleService.textBundlePath}/${baseTextBundleId}/${this.textSnippetPath}`; // Assuming a path pattern like this
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return from(runTransaction(this.firestore, transaction => {
            return transaction.get(snippetRef).then(snippetDoc => {
                if (!snippetDoc.exists()) {
                    throw new Error(`TextSnippet with ID ${textSnippetId} does not exist in BaseTextBundle with ID ${baseTextBundleId}.`);
                }

                const updatePath = `localized_content.${regionalTextBundleId}`;
                const updateObject: { [key: string]: string } = {};
                updateObject[updatePath] = localizedContent;

                transaction.update(snippetRef, updateObject);
            });
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed: Saved LocalizedText with ID ${textSnippetId} in TextSnippet with ID ${textSnippetId} in BaseTextBundle with ID ${baseTextBundleId}`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }

    updateBaseTextSnippet(textSnippetId: string, baseTextBundleId: string, text: string): Observable<void> {
        const path = `${this.textBundleService.textBundlePath}/${baseTextBundleId}/${this.textSnippetPath}`; // Assuming a path pattern like this
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return from(runTransaction(this.firestore, transaction => {
            return transaction.get(snippetRef).then(snippetDoc => {
                if (!snippetDoc.exists()) {
                    throw new Error(`TextSnippet with ID ${textSnippetId} does not exist in BaseTextBundle with ID ${baseTextBundleId}.`);
                }

                transaction.update(snippetRef, {text});
            });
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed: Updated TextSnippet with ID ${textSnippetId} in BaseTextBundle with ID ${baseTextBundleId}`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }

    deleteTextSnippet(textSnippetId: string, baseTextBundleId: string): Observable<void> {
        const path = `${this.textBundleService.textBundlePath}/${baseTextBundleId}/${this.textSnippetPath}`; // Assuming a path pattern like this
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return from(runTransaction(this.firestore, transaction => {
            return transaction.get(snippetRef).then(snippetDoc => {
                if (!snippetDoc.exists()) {
                    throw new Error(`TextSnippet with ID ${textSnippetId} does not exist in BaseTextBundle with ID ${baseTextBundleId}.`);
                }
                transaction.delete(snippetRef);
            });
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed: Deleted TextSnippet with ID ${textSnippetId} in BaseTextBundle with ID ${baseTextBundleId}`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }

    checkIfTextSnippetExists(textSnippetId: string, baseTextBundleId: string): Observable<boolean> {
        const path = `${this.textBundleService.textBundlePath}/${baseTextBundleId}/${this.textSnippetPath}`;
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return from(getDoc(snippetRef)).pipe(
            map(snippetDoc => snippetDoc.exists())
        );
    }

    duplicateTextSnippet(textSnippetId: string, baseTextBundleId: string): Observable<string> {
        const path = `${this.textBundleService.textBundlePath}/${baseTextBundleId}/${this.textSnippetPath}`;
        const snippetRef = doc(this.firestore, `${path}/${textSnippetId}`);

        return from(runTransaction(this.firestore, async (transaction) => {
            const snippetDoc = await transaction.get(snippetRef);
            if (!snippetDoc.exists()) {
                throw new Error(`TextSnippet with ID ${textSnippetId} does not exist in BaseTextBundle with ID ${baseTextBundleId}.`);
            }

            const newDocRef = doc(collection(this.firestore, path));
            const data = snippetDoc.data() as TextSnippet;
            data.localized_content = null;
            data.id = newDocRef.id;
            transaction.set(newDocRef, data);

            return newDocRef.id;
        })).pipe(
            tap({
                complete: () => console.log(`Transaction completed and new document ID was generated.`),
                error: (error) => console.error(`Transaction failed: ${error.message}`)
            })
        );
    }
}
