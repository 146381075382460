import {computed, effect, Injectable, signal} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private theme = signal('light'); // Default to 'light' theme

    constructor() {
        this.loadInitialTheme();
        this.applyThemeEffect();
    }

    toggleTheme(): void {
        const currentTheme = this.theme();
        this.theme.set(currentTheme === 'light' ? 'dark' : 'light');
    }

    private loadInitialTheme(): void {
        let prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        console.log('prefersDark', prefersDark);
        prefersDark = false;
        this.theme.set(prefersDark ? 'dark' : 'light');
    }

    private applyThemeEffect(): void {
        effect(() => {
            document.documentElement.classList.toggle('dark', this.theme() === 'dark');
        });
    }

    getThemeSignal() {
        return computed(() => this.theme());
    }
}
