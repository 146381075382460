import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {from, Observable, switchMap} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThumbnailService {

    constructor(private storage: AngularFireStorage) {
    }

    uploadFile(file: File): Observable<string> {
        const currentTime = new Date().getTime();
        const filePath = `capture-event-covers/${currentTime}-capture-event-thumbnail`;
        const fileRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, file);

        return from(uploadTask).pipe(
            switchMap(() => fileRef.getDownloadURL())
        );
    }

    uploadFileWeb(file: File): Observable<string> {
        const currentTime = new Date().getTime();
        const filePath = `capture-event-covers-web/${currentTime}-capture-event-thumbnail`;
        const fileRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, file);

        return from(uploadTask).pipe(
            switchMap(() => fileRef.getDownloadURL())
        );
    }

    getThumbnail(filePath: string): Observable<string> {
        const fileRef = this.storage.ref(filePath);
        return fileRef.getDownloadURL();
    }

}
