import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Observable} from 'rxjs';
import {AvailableLanguagesResponse} from '../interfaces/firebase_functions';

@Injectable({
    providedIn: 'root'
})
export class FirebaseFunctionService {

    constructor(private fns: AngularFireFunctions) {
    }

    disableUser(userId: string) {
        const callable = this.fns.httpsCallable('disable_user');
        return callable({userId});
    }

    setUserRole(userId: string, role: string) {
        const callable = this.fns.httpsCallable('set_user_role');
        return callable({userId, role});
    }

    enableUser(userId: string) {
        const callable = this.fns.httpsCallable('enable_user');
        return callable({userId});
    }

    getUserRole(userId: string) {
        const callable = this.fns.httpsCallable('get_user_role');
        // expected call back: {'role': user.custom_claims.get('role')}
        return callable({userId});
    }

    getDialectMatrix(language: string, stays: Array<any>) {
        const callable = this.fns.httpsCallable("calculate_user_dialect_matrix");
        return callable({"language": language, "stays": stays});
    }

    getAvailableLanguages(): Observable<AvailableLanguagesResponse> {
        const callable = this.fns.httpsCallable("get_available_languages");
        return callable({});
    }

    getRankingOfCaptureEvent(captureEventId: string) {
        const callable = this.fns.httpsCallable("getUserRankingOfCaptureEvent");
        return callable({captureEventID: captureEventId});
    }
}
