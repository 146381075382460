<div class="flex flex-col w-full h-full">
	<nav aria-label="Progress">
		<ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
			<li class="md:flex-1">
				<a class="flex flex-col border-l-4 border-sky-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
					<!-- Completed Step -->
					<span class="text-sm font-medium text-sky-600">Step 1</span>
					<span class="text-sm font-medium">Age & Language Registration</span>
				</a>
			</li>
			<li class="md:flex-1">
				<a *ngIf="currentStep === 'selectAge' || currentStep === 'selectRegion'" class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
					<span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step 2</span>
					<span class="text-sm font-medium">Location Registration</span>
				</a>
				<a *ngIf="currentStep === 'selectStayLocation' || currentStep === 'selectCurrentStay' || currentStep ==='showRegionAssignment'" class="flex flex-col border-l-4 border-sky-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
					<span class="text-sm font-medium text-sky-600">Step 2</span>
					<span class="text-sm font-medium">Location Registration</span>
				</a>
			</li>
			<li class="md:flex-1">
				<a *ngIf="currentStep !== 'showRegionAssignment'" class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
					<span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step 3</span>
					<span class="text-sm font-medium">Region Assignment</span>
				</a>
				<a *ngIf="currentStep === 'showRegionAssignment'" class="flex flex-col border-l-4 border-sky-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4" aria-current="step">
					<span class="text-sm font-medium text-sky-600">Step 3</span>
					<span class="text-sm font-medium">Region Assignment</span>
				</a>
			</li>
		</ol>
	</nav>
	
	<div class="flex flex-col w-full h-full relative">
		
		<div class="flex flex-col w-full h-full relative">
			<div [@slideInOut] *ngIf="currentStep === 'selectRegion'" class="w-full h-full flex flex-col justify-center items-center grow absolute">
				<h1 *ngIf="countryKey === null" class="text-xl font-medium mb-6">From what country are you from?</h1>
				
				<h1 *ngIf="countryKey !== null" class="text-xl font-medium mb-6">What Mother Tongue Language do you speak?</h1>
				
				
				<div *ngIf="availableLanguages" style="padding-top: 0;padding-bottom: 0;" class="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all min-w-[500px]">
					<ul class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 gap-2 grid" role="listbox">
						<ng-container *ngIf="countryKey === null">
							<li *ngFor="let result of Object.keys(availableLanguages.languages)" class="cursor-default select-none bg-sky-50 text-sky-600 rounded-md px-4 py-2 hover:bg-sky-600 hover:text-white transition" role="option" tabindex="-1" (click)="setCountry(result)">
								{{ result }}
							</li>
						</ng-container>
						
						<ng-container *ngIf="countryKey !== null">
							<li
									*ngFor="let languages of availableLanguages.languages[this.countryKey]"
									[ngClass]="{'bg-sky-600 text-white': targetLanguage === languages, 'bg-sky-50 text-sky-600': targetLanguage !== languages}"
									class="cursor-default select-none rounded-md px-4 py-2 hover:bg-sky-600 hover:text-white transition" role="option" tabindex="-1" (click)="setTargetLanguage(languages)">
								{{ languages }}
							</li>
						</ng-container>
					</ul>
				</div>
				
				<div class="w-full flex justify-center gap-6 mt-9">
					<button type="button" (click)="close()" class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3.5 py-2.5 text-sm text-gray-600 font-semibold shadow-sm hover:bg-gray-300">
						Stop Region Matcher
					</button>
					
					<button [disabled]="!isTargetLanguageApproval" (click)="toggleNext()" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 disabled:bg-gray-600">
						<svg class="mr-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"/>
						</svg>
						
						Approve Age and Continue
					</button>
				</div>
			</div>
			
			<div [@slideInOut] *ngIf="currentStep === 'selectAge'" class="w-full h-full flex flex-col justify-center items-center grow absolute">
				<h1 class="text-xl font-medium mb-4">How old are you?</h1>
				
				<input [value]="inputAge"
				       (input)="onAgeInputChange($event)"
				       class="font-extrabold text-9xl appearance-none w-[ch-3] border-none outline-none text-center border-transparent focus:border-transparent focus:ring-0"
				       placeholder="age"
				/>
				
				<div class="w-full flex justify-center gap-6 mt-14">
					<button type="button" (click)="close()" class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3.5 py-2.5 text-sm text-gray-600 font-semibold shadow-sm hover:bg-gray-300">
						Stop Region Matcher
					</button>
					
					<button [disabled]="!isAgeProvable" (click)="toggleNext()" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 disabled:bg-gray-600">
						<svg class="mr-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"/>
						</svg>
						
						Approve Age and Continue
					</button>
				</div>
			</div>
			
			<div [@slideInOut] *ngIf="currentStep === 'selectCurrentStay'" class="w-full h-full flex flex-col justify-center items-center grow absolute">
				<h1 *ngIf="!selectedPlace" class="text-xl font-medium mb-4">In what place are you currently staying?</h1>
				<h1 *ngIf="selectedPlace" class="text-xl font-medium mb-4">For how long have you been staying at that place?</h1>
				
				<div *ngIf="!selectedPlace" class="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all min-w-[500px]">
					<input type="text" class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm" placeholder="Search..."
					       role="combobox" aria-expanded="false" aria-controls="options" (input)="onInputChanged($event)" #placesInput>
					
					<ul *ngIf="results !== null" class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800" role="listbox">
						<li *ngFor="let result of results" class="cursor-default select-none rounded-md px-4 py-2 hover:bg-sky-600 hover:text-white transition" role="option" tabindex="-1" (click)="selectPlace(result)">
							{{ result.description }}
						</li>
					</ul>
					
					<div *ngIf="results?.length === 0 && results !== null" class="px-4 py-14 text-center sm:px-14">
						<svg class="mx-auto h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"/>
						</svg>
						<p class="mt-4 text-sm text-gray-900">Sorry, we couldn't find the region you described.</p>
					</div>
				</div>
				
				<div *ngIf="selectedPlace" class="flex flex-col items-center">
					<div class="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all min-w-[500px]">
						<input type="text" class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm" placeholder="Search..."
						       role="combobox" aria-expanded="false" aria-controls="options" disabled [value]="selectedPlace?.description">
						
						<div *ngIf="!_showManuelDurationInput" class="mt-4 flex flex-col justify-center gap-2">
							<button
									(click)="setCurrentStayDuration(1)"
									[ngClass]="{'bg-sky-200 text-sky-800': inputDuration === 1, 'bg-sky-50 text-sky-600': inputDuration !== 1}"
									type="button"
									class="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-sky-100">
								Less than a year
							</button>
							<button
									(click)="setCurrentStayDuration(2)"
									[ngClass]="{'bg-sky-200 text-sky-800': inputDuration === 2, 'bg-sky-50 text-sky-600': inputDuration !== 2}"
									type="button"
									class="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-sky-100">
								1 - 3 Years
							</button>
							<button (click)="showManuelDurationInput()" type="button" class="rounded-md bg-sky-50 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100">More than 3 Years</button>
						</div>
						
						
						<div *ngIf="_showManuelDurationInput" class="mt-2 flex flex-col gap-2">
							<p class="rounded-md bg-sky-50 px-2.5 py-1.5 text-sm font-medium text-sky-600 shadow-sm ">Enter the approximate duration of your Stay in <span class="font-extrabold underline">Years</span></p>
							
							<input
									(input)="onDurationChange($event)"
									[value]="inputDuration"
									type="text"
									class="font-extrabold text-3xl text-center appearance-none w-[ch-30] border-none outline-none text-center border-transparent focus:border-transparent focus:ring-0 my-6"
									placeholder="duration"
									min="0"
									max="130"
							/>
						</div>
					</div>
					
					
					<div class="w-full flex justify-center gap-6 mt-16">
						<button type="button" (click)="resetPlace()" class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3.5 py-2.5 text-sm text-gray-600 font-semibold shadow-sm hover:bg-gray-300">
							Edit Place
						</button>
						
						<button (click)="approveCurrentStay()" [disabled]="!isCurrentStayComplete" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 disabled:bg-gray-600">
							<svg class="mr-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"/>
							</svg>
							
							Approve Current Stay and Continue
						</button>
					</div>
				</div>
			</div>
			
			<div [@slideInOut] *ngIf="currentStep === 'selectStayLocation'" class="w-full h-full flex flex-col justify-center items-center grow  absolute">
				<h1 *ngIf="!selectedPlace" class="text-xl font-medium mb-4">In what place where you previously staying?</h1>
				<h1 *ngIf="selectedPlace" class="text-xl font-medium mb-4">For how long have you been staying at that place?</h1>
				
				<div *ngIf="!selectedPlace" class="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all min-w-[500px]">
					<input type="text" class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm" placeholder="Search..."
					       role="combobox" aria-expanded="false" aria-controls="options" (input)="onInputChanged($event)" #placesInput>
					
					<ul *ngIf="results !== null" class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800" role="listbox">
						<li *ngFor="let result of results" class="cursor-default select-none rounded-md px-4 py-2 hover:bg-sky-600 hover:text-white transition" role="option" tabindex="-1" (click)="selectPlace(result)">
							{{ result.description }}
						</li>
					</ul>
					
					<div *ngIf="results?.length === 0 && results !== null" class="px-4 py-14 text-center sm:px-14">
						<svg class="mx-auto h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"/>
						</svg>
						<p class="mt-4 text-sm text-gray-900">Sorry, we couldn't find the region you described.</p>
					</div>
				</div>
				
				<div *ngIf="selectedPlace" class="flex flex-col items-center">
					<div class="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all min-w-[500px]">
						<input type="text" class="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm" placeholder="Search..."
						       role="combobox" aria-expanded="false" aria-controls="options" disabled [value]="selectedPlace?.description">
						
						<div *ngIf="!_showManuelDurationInput" class="mt-4 flex flex-col justify-center gap-2">
							<button
									(click)="setCurrentStayDuration(1)"
									[ngClass]="{'bg-sky-200 text-sky-800': inputDuration === 1, 'bg-sky-50 text-sky-600': inputDuration !== 1}"
									type="button"
									class="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-sky-100">
								Less than a year
							</button>
							<button
									(click)="setCurrentStayDuration(2)"
									[ngClass]="{'bg-sky-200 text-sky-800': inputDuration === 2, 'bg-sky-50 text-sky-600': inputDuration !== 2}"
									type="button"
									class="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-sky-100">
								1 - 3 Years
							</button>
							<button (click)="showManuelDurationInput()" type="button" class="rounded-md bg-sky-50 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100">More than 3 Years</button>
						</div>
						
						<div *ngIf="_showManuelDurationInput" class="mt-2 flex flex-col gap-2">
							<p class="rounded-md bg-sky-50 px-2.5 py-1.5 text-sm font-medium text-sky-600 shadow-sm ">Enter the approximate duration of your Stay in <span class="font-extrabold underline">Years</span></p>
							
							<input
									(input)="onDurationChange($event)"
									[value]="inputDuration"
									type="text"
									class="font-extrabold text-3xl text-center appearance-none w-[ch-30] border-none outline-none text-center border-transparent focus:border-transparent focus:ring-0 my-6"
									placeholder="duration"
									min="0"
									max="130"
							/>
						</div>
					</div>
					
					<div class="w-full flex justify-center gap-6 mt-16">
						<button type="button" (click)="resetPlace()" class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3.5 py-2.5 text-sm text-gray-600 font-semibold shadow-sm hover:bg-gray-300">
							Edit Place
						</button>
						
						<button [disabled]="!isCurrentStayComplete" (click)="approveCurrentStay()" type="button" class="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 disabled:bg-gray-600">
							<svg class="mr-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd"/>
							</svg>
							
							Approve Entry and Continue
						</button>
					</div>
				</div>
			</div>
			
			<div [@slideInOut] *ngIf="currentStep === 'showRegionAssignment'" class="w-full h-full flex flex-col justify-center items-center grow  absolute">
				
				<h1 *ngIf="!dialectMatrix" class="text-xl font-medium mb-4">Hold on we're nearly done, let us do some math.</h1>
				
				<section *ngIf="dialectMatrix && targetRegion" class="isolate overflow-hidden bg-white px-6 lg:px-8">
					<div class="relative mx-auto max-w-2xl py-24 sm:py-32 lg:max-w-4xl">
						<div class="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.sky.100),white)] opacity-20 lg:left-36"></div>
						<div class="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-sky-600/10 ring-1 ring-sky-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center"></div>
						<figure class="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
							<div class="relative col-span-2 lg:col-start-1 lg:row-start-2">
								<blockquote class="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
									<p>Analysis indicates that your dialect is significantly shaped by Region {{ targetRegion }}, Switzerland.</p>
								</blockquote>
							</div>
							<div class="col-end-1 w-16 lg:row-span-4 lg:w-72">
								<img class="rounded-xl bg-sky-50 lg:rounded-3xl shadow-2xl" [src]="regionThumbnail" alt="">
							</div>
							<figcaption class="text-base lg:col-start-1 lg:row-start-3">
								<div class="mt-1 text-gray-500">Dialect assigment for <span class="font-semibold text-gray-900">{{ (user$ | async)?.userName ?? 'Unknown Name' }}</span> complete.</div>
								<button type="button" (click)="closeRegionalMatcher.emit()" class="rounded bg-sky-50 px-2 py-1 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100 mt-4">Close Region Matcher</button>
							</figcaption>
						</figure>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>
