import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NgClass} from '@angular/common';
import {ThemeService} from '../../services/theme.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        NgClass
    ],
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.css',
    animations: [
        trigger('slideEffect', [
            state('disabled', style({transform: 'translateX(0)'})),
            state('enabled', style({transform: 'translateX(100%)'})), // Adjusted for full slide to the right
            transition('disabled <=> enabled', animate('200ms ease-in-out'))
        ])
    ]
})
export class SettingsComponent {
    enabled: boolean = true;

    constructor(private themeService: ThemeService) {
        
    }

    toggleEnabled() {
        this.enabled = !this.enabled;
        this.themeService.toggleTheme()
    }

    protected readonly environment = environment;
}
