<div class="w-full p-8 border-t-4 border-gray-400 dark:border-gray-600 bg-white dark:bg-gray-900">
	<header class="md:flex md:items-center md:justify-between mt-5 mb-8 w-full mx-auto max-w-7xl">
		<div class="min-w-0 flex-1">
			<h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:truncate sm:text-3xl sm:tracking-tight">Settings</h2>
		</div>
		<div class="mt-4 flex md:ml-4 md:mt-0">
		</div>
	</header>
	
	<main class="mx-auto max-w-7xl">
		<div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
			<div>
				<h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-white">Tenant Management</h2>
				<p class="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400">Review and manage tenant details. Changes are visible to all users.</p>
				
				<dl class="mt-6 space-y-6 divide-y divide-gray-300 dark:divide-gray-700 border-t border-gray-300 dark:border-gray-700 text-sm leading-6">
					<div class="pt-6 sm:flex">
						<dt class="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">Application Mode</dt>
						<dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
							<div class="text-gray-700 dark:text-gray-300">{{environment.production ? "Production" : "Development"}}</div>
							<button hidden type="button" class="font-semibold text-sky-600 dark:text-sky-400 hover:text-sky-500 dark:hover:text-sky-300">Update</button>
						</dd>
					</div>
					<div class="pt-6 sm:flex">
						<dt class="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">Tenant Application Owner</dt>
						<dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
							<div class="text-gray-700 dark:text-gray-300">Ylabs LLC Switzerland</div>
							<button hidden type="button" class="font-semibold text-sky-600 dark:text-sky-400 hover:text-sky-500 dark:hover:text-sky-300">Update</button>
						</dd>
					</div>
					<div class="pt-6 sm:flex">
						<dt class="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">Admin Contact</dt>
						<dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
							<div class="text-gray-700 dark:text-gray-300">{{ 'admin@ylabs.ch' }}</div>
							<button hidden type="button" class="font-semibold text-sky-600 dark:text-sky-400 hover:text-sky-500 dark:hover:text-sky-300">Update</button>
						</dd>
					</div>
					<div class="pt-6 sm:flex">
						<dt class="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">Provider</dt>
						<dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
							<div class="text-gray-700 dark:text-gray-300">Ylabs Consulting</div>
							<button hidden type="button" class="font-semibold text-sky-600 dark:text-sky-400 hover:text-sky-500 dark:hover:text-sky-300">Update</button>
						</dd>
					</div>
				</dl>
			</div>
			
			
			<div>
				<h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-white">Application Settings</h2>
				<p class="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-400">Choose how the Admin Panel should look like.</p>
				
				<dl class="mt-6 space-y-6 divide-y divide-gray-300 dark:divide-gray-700 border-t border-gray-300 dark:border-gray-700 text-sm leading-6">
					<div class="flex pt-6">
						<dt class="flex-none pr-6 font-medium text-gray-900 dark:text-white sm:w-64" id="timezone-option-label">Dark Mode</dt>
						<dd class="flex flex-auto items-center justify-end">
							<button type="button"
							        [attr.aria-checked]="enabled ? 'true' : 'false'"
							        [ngClass]="enabled ? 'bg-sky-600' : 'bg-gray-600'"
							        (click)="toggleEnabled()"
							        class="flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-500 dark:ring-gray-500 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
							        role="switch">
                                <span aria-hidden="true"
                                      [@slideEffect]="enabled ? 'enabled' : 'disabled'"
                                      class="h-4 w-4 transform rounded-full bg-white dark:bg-white shadow-sm ring-1 ring-gray-500 dark:ring-gray-500 transition duration-200 ease-in-out"></span>
							</button>
						</dd>
					</div>
				</dl>
			</div>
		</div>
	</main>
</div>
