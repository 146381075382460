<div class="absolute z-10 top-0 right-0 left-0 bottom-0" role="dialog" aria-modal="true" *ngIf="show">
	<div [@backdrop] class="absolute inset-0 bg-black backdrop-blur bg-opacity-10 transition-opacity"></div>
	
	<div class="relative overflow-y-auto h-full content-center">
		<div [@modal] class="mx-auto max-w-xl transform rounded-xl bg-white dark:bg-gray-800 p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
			<input [(ngModel)]="searchValue" (input)="input$.next(searchValue)" type="text" class="w-full rounded-md border-0 bg-gray-200 dark:bg-gray-700 px-4 py-2.5 text-gray-900 dark:text-white focus:ring-0 sm:text-sm" placeholder="Search..." role="combobox" aria-expanded="false" aria-controls="options">
			
			<ul *ngIf="searchType === 'users'" class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-900 dark:text-white z-20" id="options" role="listbox">
				<li (click)="selectUser(user)" *ngFor="let user of users" class="cursor-default select-none rounded-md px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-orange-800" id="option-1" role="option" tabindex="-1">
					<span class="font-thin">{{ user.id + " -> " }}</span>{{ user.userName + ", " + user.email }}
				</li>
			</ul>
			
			<ul *ngIf="searchType === 'captureEvents'" class="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-900 dark:text-white z-20" id="options" role="listbox">
				<li (click)="selectCaptureEvent(captureEvent)" *ngFor="let captureEvent of captureEvents" class="cursor-default select-none rounded-md px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-orange-800" id="option-1" role="option" tabindex="-1">
					<span class="font-thin">{{ captureEvent.id + " -> " }}</span>{{ captureEvent.name + " " + captureEvent.placeDescription }}
				</li>
			</ul>
			
			<div *ngIf="!checkIfGotData()" class="px-4 py-14 text-center sm:px-14">
				<svg class="mx-auto h-6 w-6 text-gray-900 dark:text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
					<path *ngIf="searchType === 'users'" stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
					<path *ngIf="searchType === 'captureEvents'" stroke-linecap="round" stroke-linejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"/>
				</svg>
				<p *ngIf="searchType === 'users'" class="mt-4 text-sm text-gray-900 dark:text-gray-300">No people found using that search term.</p>
				<p *ngIf="searchType === 'captureEvents'" class="mt-4 text-sm text-gray-900 dark:text-gray-300">No capture event found using that search term.</p>
				<p class="text-xs text-gray-900 dark:text-gray-400 font-light">*case sensitive</p>
			</div>
		</div>
	</div>
</div>
