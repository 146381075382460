export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyAC621yEN8XeUgrQFgN-4y4AGkKHB2PgQA",
        authDomain: "ylabsdata-dev.firebaseapp.com",
        projectId: "ylabsdata-dev",
        storageBucket: "ylabsdata-dev.appspot.com",
        messagingSenderId: "718090384926",
        appId: "1:718090384926:web:229ee351b1de2f8dd3200f"
    },
    firebaseAdmin: {
        "type": "service_account",
        "project_id": "ylabsdata-dev",
        "private_key_id": "be064a5013a80f8799430189a5fe335f8635ce73",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCFbPf2d0LAvHEM\nSzanR9gnM1nwZMD2Cr0XbEFpz3u1zikPfkp66Jjwp5kA/JQUOG3/nPx3kT3GaeOc\n+tFDijXc8BhWU4cHQCHJ+FW6ivM1rRxK7X4XyarVSyTeg5r4QLxcjF9SNhz5+4Oz\nkC5v/NcR5dLBAfLvnR+BqPKmA/+hEZrtXtB9kHodm40jttUpajYti0elhbLHvLKM\n2rnTKCpSjH37kNkZ60Fcd/VXWEydcMyVSLFdeSFw81QkZ5ryCw4mHGh6VbxzQxj5\nNyA6TvqcBXTy/Y2tLk18KoizJd/gSQ6RLiGO4uYp/Umr90B5wYiBbIta/Jip73sm\nH7yNCVStAgMBAAECggEAIFfzvQaYgy27DsKdz0u6cXhAhWZ7IzmjsG2g5sQx5jbB\nW9zes7A2QOIz1BomUEeEnLnitp5Z9gLdtwRTHZb/qxz1nVmWqjKI7lOZfoSn49tg\nuCYdkqkjlRxn5dvLwYG514p8oep1uECY4rqhEyscvJ00a26sRBsX09/PYGCoQ1Qu\nVdoBSt82BwVvTVqvyh4dcCiOWhQNeFUMyydCTjq23XL8MLeFjrJ5FQtJPLLmnfjp\nTfbzfgVVB9FeS9h34I+k9EbIJhm6HOACP8OpwVLmiuSmOjZmoMJzhIu9ETcdgM36\nnm/gsZEHPvB463Drw1zr+SUWnjfqKukrEizeQpg26QKBgQC8ksfc8Hw7PzgaJWMX\njhhb+KP6eiNKephT0FsTr2qrZoMnAx33+wsjXOwVkZUrFU23DeoYZTlnebKJKFIH\nq4SNSdvSCREhijWpILu0R470SK+0jSi85LmjV1NkedtuQC4SG5t7N1PgabjHprwe\noksumpx8n3MAxcRt2/FBkKpIGQKBgQC1IjJyF72QzxjKpS8wCCGmeeNNC+QZw2YW\n1xYCia1rpZ/e+ErQvsVOk+svKaWjHXIGxCry2rkGL9r4ajMPeFd3V54ij2MdGByT\n/TUcmENcRvbeuw52Tt2mu1dwlsw4Cwng2FwxubnZPZIvF0DLAahz0et7xHXJdf++\n5LRK5u6TtQKBgH3YFrlDZCTF09LfEHovweHe1M/boqGZnv6QFbfVYQJxryTh16Ye\nSxiWcpcSSNIvrWV0S71sEBsWBCe0WMn1lMgVejV5B42yho0P4zQ0bRppR5jee0lT\n+u8e3/A1wlKZUiWTdveLvNYOt9nFU9W9yR6Y38XrQvxI/wWxuhdfS8RBAoGAaMp2\n+tDHR+64Pgygpx4dMa3Ula9qcIk+F7qpriAup1dzjVCCIBAuIA6tgmSKwjp3zHhT\nDJsDbhPjj9II5ktN9upGv2HLCnr3lZpozrk8Rzd6/VKkxEdOLna12Jo1XbrtJRqn\nA9GaNpdLalj+kDPOW+s3XCxFpJ7SrYKs16SQo/0CgYEAquXmzXKG6on2qy4DBMfN\nOUD+uRE0lQTTjFXQSo/5xutTAC6QZxEyTpJ/aLBbMEogy6MZuRP8GCV3QtKtukaR\n3xS9Lm4N3qdxtZaS5dXhQQ1bnNYYtvjbGAQ8j/j99uTkYbMcuvO0vhKNsS5g7g5Y\nqi9EZ36jFRbp9pczLKvSL1c=\n-----END PRIVATE KEY-----\n",
        "client_email": "firebase-adminsdk-zm29d@ylabsdata-dev.iam.gserviceaccount.com",
        "client_id": "117763885702902341146",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-zm29d%40ylabsdata-dev.iam.gserviceaccount.com",
        "universe_domain": "googleapis.com"
    }
};

// TODO: Not secure, but for development purposes.
