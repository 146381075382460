import {Injectable} from '@angular/core';
import {BehaviorSubject, delay, Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private alertTitle = new BehaviorSubject<string | null>(null);
    private alertMessage = new BehaviorSubject<string | null>(null);

    constructor() {
    }

    getAlertTitle(): Observable<string | null> {
        return this.alertTitle.asObservable();
    }

    getAlertMessage(): Observable<string | null> {
        return this.alertMessage.asObservable();
    }

    showAlert(title: string, message: string, duration = 3000): void {
        this.alertTitle.next(title);
        this.alertMessage.next(message);
        of(null).pipe(delay(duration)).subscribe(() => {
            this.alertTitle.next(null);
            this.alertMessage.next(null);
        });
    }
}
