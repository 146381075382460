import {DialectCaptureInterface} from './DialectCapture.interface';

export interface BaseTextBundle {
    id: string;
    name?: string;
    categories?: string[];
    description?: string;
}

export interface RegionalTextBundle {
    id: string;
    base_text_bundle: BaseTextBundle;
    name: string;
    description: string;
    country: string;
    region_name: string;
    postalcodes: string[];
}

export interface RegionalCaptureData {
    id: string;
    region_name: string;
    region_postal_codes: string[];
    dialect_captures: DialectCaptureInterface[];
}

export enum TextBundleType {
    Base,
    Regional
}
