import {inject, Injectable} from '@angular/core';
import {doc, Firestore, getDoc, setDoc} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {UserService} from './user.service';
import {UserRegionData} from '../interfaces/User.interface';

@Injectable({
    providedIn: 'root'
})
export class DialectMatrixService {
    private firestore: Firestore = inject(Firestore);


    constructor(private storage: AngularFireStorage, private userService: UserService) {
    }

    async getRegionThumbnail(countryKey: string, region: string) {
        try {
            const docRef = doc(this.firestore, 'Countries', countryKey);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const flagLocation = data['canton_flags'][region];
                const fileRef = this.storage.ref(flagLocation);
                return fileRef.getDownloadURL();
            } else {
                throw new Error('No such document!');
            }
        } catch (error) {
            console.error("Error fetching document or getting download URL:", error);
            throw error;
        }
    }

    async saveRegionForUser(userId: string, chosenLanguage: string, dialectMatrix: object, mainRegion: string, chosenCountry: string) {
        try {
            const userDocRef = this.userService.getRef(userId);

            const regionData = {
                chosenLanguage: chosenLanguage,
                chosenCountry: chosenCountry,
                dialectMatrix: dialectMatrix,
                mainRegion: mainRegion
            };

            await setDoc(userDocRef, {regionData: regionData}, {merge: true});
            console.log('Region data saved successfully for user:', userId);
        } catch (error) {
            console.error('Error saving region data for user:', error);
            throw error;
        }
    }

    async getRegionDataFromUser(userId: string): Promise<UserRegionData> {
        try {
            const userDocRef = this.userService.getRef(userId);
            const userDocSnap = await getDoc(userDocRef);

            if (!userDocSnap.exists()) {
                throw new Error('No such document!');
            }

            const data = userDocSnap.data();

            const thumbnailUrl = await this.getRegionThumbnail(data['regionData']['chosenCountry'], data['regionData']['mainRegion']);
            data['regionData']['mainRegionThumbnail'] = await new Promise(resolve => thumbnailUrl.subscribe(resolve));

            return data['regionData'];

        } catch (error) {
            console.error('Error fetching document:', error);
            throw error;
        }
    }


}
