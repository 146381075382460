import {inject, Injectable} from '@angular/core';
import {collection, collectionData, doc, docData, Firestore, getDoc, getDocs} from '@angular/fire/firestore';
import {forkJoin, from, mergeMap, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {BaseTextBundle, RegionalTextBundle} from '../interfaces/TextBundle.interface';

@Injectable({
    providedIn: 'root'
})
export class TextBundlesService {
    private firestore: Firestore = inject(Firestore);
    public textBundlePath = 'BaseTextBundles';
    public regionalTextBundlePath = "RegionalTextBundles"

    constructor() {
    }

    getBaseTextBundleRef(baseTextBundleID: string) {
        return doc(this.firestore, `${this.textBundlePath}/${baseTextBundleID}`);
    }

    getBaseTextBundleSubscription(id: string): Observable<BaseTextBundle> {
        return docData(doc(this.firestore, `${this.textBundlePath}/${id}`), {idField: 'id'}) as Observable<BaseTextBundle>;
    }

    getBaseTextBundleData(id: string): Observable<BaseTextBundle> {
        return from(getDoc(doc(this.firestore, `${this.textBundlePath}/${id}`))).pipe(
            map(docSnapshot => docSnapshot.data() as BaseTextBundle),
            catchError(err => throwError(() => new Error(`Error retrieving document: ${err.message}`)))
        );
    }

    getAllBaseTextBundlesSubscription(): Observable<BaseTextBundle[]> {
        return collectionData(collection(this.firestore, this.textBundlePath), {idField: 'doc_id'}) as Observable<BaseTextBundle[]>;
    }

    getAllBaseTextBundlesData(): Observable<BaseTextBundle[]> {
        return from(getDocs(collection(this.firestore, this.textBundlePath))).pipe(
            map(querySnapshot => querySnapshot.docs.map(doc => doc.data() as BaseTextBundle)),
            catchError(err => throwError(() => new Error(`Error retrieving collection: ${err.message}`)))
        );
    }

    // Regional Text Bundles

    getRegionalTextBundleRef(baseBundleId: string, regionalTextBundleId: string) {
        return doc(this.firestore, `${this.textBundlePath}/${baseBundleId}/${this.regionalTextBundlePath}/${regionalTextBundleId}`);
    }

    getRegionalTextBundleSubscription(baseBundleId: string, regionalTextBundleId: string): Observable<RegionalTextBundle> {
        return docData(doc(this.firestore, `${this.textBundlePath}/${baseBundleId}/${this.regionalTextBundlePath}/${regionalTextBundleId}`)) as Observable<RegionalTextBundle>;
    }

    getRegionalTextBundleData(baseBundleId: string, regionalTextBundleId: string): Observable<RegionalTextBundle> {
        return from(getDoc(doc(this.firestore, `${this.textBundlePath}/${baseBundleId}/${this.regionalTextBundlePath}/${regionalTextBundleId}`))).pipe(
            map(docSnapshot => docSnapshot.data() as RegionalTextBundle),
            catchError(err => throwError(() => new Error(`Error retrieving document: ${err.message}`)))
        );
    }

    getAllRegionalTextBundlesSubscription(baseBundleId: string): Observable<RegionalTextBundle[]> {
        return collectionData(collection(this.firestore, `${this.textBundlePath}/${baseBundleId}/${this.regionalTextBundlePath}`), {idField: 'doc_id'}) as Observable<RegionalTextBundle[]>;
    }

    getAllRegionalTextBundlesFromAllBase(): Observable<RegionalTextBundle[]> {
        return this.getAllBaseTextBundlesData().pipe(
            mergeMap(baseBundles => forkJoin(
                baseBundles.map(bundle => this.getAllRegionalTextBundlesData(bundle.id))
            )),
            map(nestedRegionalBundles => nestedRegionalBundles.flat())
        );
    }

    getAllRegionalTextBundlesData(baseBundleId: string): Observable<RegionalTextBundle[]> {
        return from(getDocs(collection(this.firestore, `${this.textBundlePath}/${baseBundleId}/${this.regionalTextBundlePath}`))).pipe(
            map(querySnapshot => querySnapshot.docs.map(doc => doc.data() as RegionalTextBundle)),
            catchError(err => throwError(() => new Error(`Error retrieving collection: ${err.message}`)))
        );
    }
}
