import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../services/alert.service';
import {NgIf} from '@angular/common';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-alert',
    standalone: true,
    imports: [
        NgIf
    ],
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.css',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({opacity: 0, transform: 'translateY(-20px)'}),
                animate('300ms ease-out', style({opacity: 1, transform: 'translateY(0)'}))
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate('300ms ease-in', style({opacity: 0, transform: 'translateY(20px)'}))
            ])
        ])
    ]
})
export class AlertComponent implements OnInit {
    message: string | null = null;
    title: string | null = null;

    constructor(private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.alertService.getAlertTitle().subscribe(title => {
            this.title = title;
        });
        this.alertService.getAlertMessage().subscribe(message => {
            this.message = message;
        });
    }
}
