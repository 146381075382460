import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {from, map, Observable, tap} from 'rxjs';

export interface AudioRecordingMetaData {
    userID: string;
    microphoneBrand?: string;
    microphoneName: string;
    label?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CaptureAudioService {

    constructor(private storage: AngularFireStorage) {
    }

    getAudioRecording(recordingPath: string): Observable<string> {
        const fileRef = this.storage.ref(recordingPath);
        return from(fileRef.getDownloadURL());
    }

    getAudioRecordingMetaData(recordingPath: string): Observable<AudioRecordingMetaData | null> {
        const fileRef = this.storage.ref(recordingPath);
        return from(fileRef.getMetadata()).pipe(
            map(metadata => {
                if (metadata && metadata.customMetadata) {
                    return metadata.customMetadata;
                } else {
                    console.error('No custom metadata found for the file:', metadata);
                    return null; // Or provide a default value if necessary
                }
            }),
            tap(customMetadata => {
                if (customMetadata) {
                    console.log('Custom Metadata:', customMetadata);
                } else {
                    console.log('No custom metadata to display.');
                }
            })
        );
    }
}
