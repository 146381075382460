import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';
import {BaseTextBundle} from '../../interfaces/TextBundle.interface';
import {NgForOf} from '@angular/common';
import {TextBundlesService} from '../../services/text-bundles.service';

@Component({
    selector: 'app-base-text-bundles',
    standalone: true,
    imports: [RouterLink, NgForOf],
    templateUrl: './base-text-bundles.component.html',
    styleUrl: './base-text-bundles.component.css',
})
export class BaseTextBundlesComponent {
    public baseTextBundles: BaseTextBundle[] = [];

    constructor(private testBundleService: TextBundlesService) {
        this.testBundleService.getAllBaseTextBundlesData().subscribe((bundles) => {
            this.baseTextBundles = bundles;
        });
    }
}
