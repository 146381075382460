


<main class="bg-white h-full">
	<header class="relative isolate pt-16">
		<div aria-hidden="true" class="absolute inset-0 -z-10 overflow-hidden">
			<div class="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
				<div class="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#0E80B5] to-[#0507D7]" style="clip-path: polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)"></div>
			</div>
			<div class="absolute inset-x-0 bottom-0 h-px bg-gray-900/5"></div>
		</div>
		
		<div class="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
			<div class="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
				<div class="flex items-center gap-x-6">
					<img alt="" class="h-16 w-16 flex-none rounded-full ring-gray-900/10" src="assets/ylabs-logo-fav.png">
					<h1>
						<div class="text-sm leading-6 text-gray-500">Ranking For Capture Event <span class="text-gray-700">{{captureEventID}}</span></div>
						<div class="mt-1 text-base font-semibold leading-6 text-gray-900">{{captureEventName}}</div>
					</h1>
				</div>
			</div>
		</div>
	</header>
	
	<div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
		<div class="w-full flex justify-center">
			<div class="flex justify-center items-end w-[600px]">
				<!-- Third Place -->
				<div class="flex flex-col items-center bg-blue-200 w-1/3 h-40 p-5">
					<div class="flex justify-center items-center w-16 h-16 bg-blue-500 text-white rounded-full text-2xl font-bold">
						{{ userRanking[2].user_name[0] }}
					</div>
					<p class="mt-2 text-lg font-semibold">{{ userRanking[2].user_name }}</p>
					<p class="text-sm">Third Place</p>
				</div>
				
				<!-- First Place -->
				<div class="flex flex-col items-center bg-yellow-300 w-1/3 h-56 mx-2 p-5">
					<div class="flex justify-center items-center w-20 h-20 bg-yellow-600 text-white rounded-full text-2xl font-bold">
						{{ userRanking[0].user_name[0] }}
					</div>
					<p class="mt-2 text-lg font-semibold">{{ userRanking[0].user_name }}</p>
					<p class="text-sm">First Place</p>
				</div>
				
				<!-- Second Place -->
				<div class="flex flex-col items-center bg-green-200 w-1/3 h-48 p-5">
					<div class="flex justify-center items-center w-16 h-16 bg-green-500 text-white rounded-full text-2xl font-bold">
						{{ userRanking[1].user_name[0] }}
					</div>
					<p class="mt-2 text-lg font-semibold">{{ userRanking[1].user_name }}</p>
					<p class="text-sm">Second Place</p>
				</div>
			</div>
		</div>

		<div class="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 mt-8">
			<div class="px-4 sm:px-6 lg:px-8 bg-white col-start-1 col-end-4">
				<div class="sm:flex sm:items-center">
					<div class="sm:flex-auto">
						<h1 class="text-base font-semibold leading-6 text-gray-900">Ranking</h1>
						<p class="mt-2 text-sm text-gray-700">The higher the rank the better, pretty self explanatory huh?</p>
					</div>
				</div>
				<div class="mt-8 flow-root">
					<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table class="min-w-full divide-y divide-gray-300">
								<thead>
								<tr class="divide-x divide-gray-200">
									<th class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0" scope="col">User Ranking</th>
									<th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">User Name</th>
									<th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">User ID</th>
								</tr>
								</thead>
								<tbody class="divide-y divide-gray-200 bg-white">
								<tr *ngFor="let userRodent of userRanking" class="divide-x divide-gray-200">
									<td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{{ userRodent.score }}</td>
									<td class="whitespace-nowrap p-4 text-sm text-gray-500">{{ userRodent.user_name }}</td>
									<td class="whitespace-nowrap p-4 text-sm text-gray-500">{{ userRodent.user_id }}</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
